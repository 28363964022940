import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import ReceiptsListTable from './components/ReceiptList';

class ReceiptsPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    loading: true,
    data: []
  }

  componentDidMount() {
    setTimeout(() => {
      this.load();
    }, 600);
  }

  load = () => {
    const xhr = this.createCORSRequest('GET', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/receipts');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('test', body);
        this.setState({ data: body.receipts });
      } else {
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    this.setState({
      data: []
    }, () => {
      xhr.send();
    });
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo} >
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Receipts</h3>
            </Col>
          </Row>
          <ReceiptsListTable data={this.state.data} />
        </Container>
      </LoadingScreen>
    );
  }
}

export default ReceiptsPage;
