import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';

class ReceiptPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
      loading: true
  }

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      this.load(queries.t);
    }, 600);
  }

  load = (id) => {
    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/receipt');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
        this.props.history.push('/pages/receipts');
    };

    xhr.send(JSON.stringify({
        timestamp: id
    }));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
        <LoadingScreen
            loading={this.state.loading}
            bgColor="#FFFFFF"
            spinnerColor="#d28b41"
            spinnerType="ball-clip-rotate"
            textColor="#343a40"
            logoSrc={Logo} >
            <Container className="dashboard">
                <Row>
                <Col md={12}>
                    <h3 className="page-title">Approve Receipt</h3>
                </Col>
                </Row>
            </Container>
        </LoadingScreen>
    );
  }
}

export default withRouter(ReceiptPage);
