/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/CartIcon';

export default class TopbarCart extends PureComponent {
  static propTypes = {
    basket: PropTypes.array,
    updateBasket: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  empty = () => {
    console.log(this.props);  
    this.props.updateBasket([]);
  }

  render() {
    return (
      <div className="topbar__collapse">
        <button className={`topbar__btn topbar__btn--mail ${this.props.basket.length > 0 ? 'topbar__btn--new' : ''}`} onClick={this.toggle}>
          <Icon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Shopping Cart</p>
            <button className="topbar__collapse-button" onClick={this.empty}>Empty Cart</button>
          </div>
          {this.props.basket.map((notification, index) => (
            <div className="topbar__collapse-item" key={index}>
              <p className="topbar__collapse-name">{notification.title}</p>
              <p className="topbar__collapse-date">x{notification.quantity}</p>
            </div>
          ))}
          <Link className="topbar__collapse-link" to="/pages/checkout">
            Checkout
          </Link>
        </Collapse>
      </div>
    );
  }
}
