import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EditTable from '../../EditTable';
import Pagination from '../../Pagination';

export default class EditableTable extends PureComponent {
  static propTypes = {
    stock: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'expiry',
        name: 'Expiry',
        editable: false,
      },
      {
        key: 'purchase',
        name: 'Last Stocked',
        editable: false,
      },
      {
        key: 'quantity',
        name: 'Quantity',
        editable: true,
      },
      {
        key: 'price',
        name: 'Price',
        editable: true,
      },
      {
        key: 'note',
        name: 'Notes',
        editable: true,
      },
    ];

    this.state = {
      pageOfItems: [],
      pageSize: 10
    };
  }

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }

  render() {
    return (
      <main>
        {this.props.stock.length > 0 && <EditTable heads={this.heads} rows={this.props.stock} />}
      </main>
    );
  }
}
