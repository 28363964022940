import React, { Component } from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import RevenueChart from './components/RevenueChart';
import DoubleSliderType from '../DoubleSliderType';
import BudgetStatistic from './components/BudgetStatistic';
import VisitorsSessions from './components/VisitorsSessions';
import Panel from '../Panel';
import Config from '../Config';

class TimeDashboardPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    token: PropTypes.string,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getOneMonthAgo().getTime() > new Date('17 OCT 2018').getTime() ? new Date() : new Date('17 OCT 2018').getTime(),
    endDate: this.getTomorrow().getTime(),
    enableSlider: true,
    error: ''
  };

  getTomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
  }

  getOneMonthAgo() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    console.log(d.getTime());
    return d;
  }

  loadComplete = () => {
    this.setState({
      enableSlider: true
    });
  }

  updateDateRange = (props) => {
    if (props[0] !== this.state.startDate || props[1] !== this.state.endDate) {
      this.setState({
        enableSlider: false,
        startDate: props[0],
        endDate: props[1]
      });
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <Container className="dashboard">
        <Alert color="danger" className="bold-text" isOpen={this.state.error}>
          <div className="alert__content">
            {this.state.error}
          </div>
        </Alert>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Time Dashboard</h3>
          </Col>
        </Row>
        <Row>
          <Panel md={12} lg={12} xl={12} title="Revenue Timeline" completed={this.state.updated} loading={this.startLoad} >
            <DoubleSliderType 
              start={this.state.startDate}
              end={this.state.endDate}
              enabled={this.state.enableSlider} 
              update={this.updateDateRange}
            />
            <RevenueChart startDate={this.state.startDate} endDate={this.state.endDate} completion={this.loadComplete} config={this.props.config} />
          </Panel>
        </Row>
      </Container>
    );
  }
}

export default TimeDashboardPage;
