import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import AWS from 'aws-sdk';
import { notify } from 'react-notify-toast';
import Config from '../Config';
import SkuForm from './components/SkuForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class PromoPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  state = {
    loading: true,
    branch_id: '',
    promo_id: '',
    error: '',
    disabled: true,
    data: {},
    type: 'PERCENT'
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      if (queries.d) {
        this.load(queries.d);
      } else {
        this.setState({
          disabled: false,
          loading: false
        });
      }
    }, 600);

    this.onSelectCat = this.onSelectCat.bind(this);
  }

  onSelectCat = (e) => {
    this.setState({
      type: e.target.value
    });
  }

  load = (promoId) => {
    this.setState({
      promo_id: promoId,
      disabled: true
    });

    const params = {
      promo_id: promoId
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/promo');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          data: {
            name: body.promo.name,
            description: body.promo.desc,
            disabled: body.promo.public,
            id: body.promo.promo_id,
            amount: body.promo.amount
          },
          type: body.promo.type
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  submit = (e) => {
    this.setState({
      loading: true
    });

    const params = {
      amount: e.amount,
      desc: e.description,
      expiry: 2556115199000,
      max: 100000000,
      min: 0,
      name: e.name,
      promo_id: e.id,
      public: e.disabled ? e.disabled : false,
      stack: true,
      type: this.state.type
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/createpromo');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      console.log('testb', data);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        notify.show('Promo created successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/pages/promos');
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  delete = () => {
    console.log('delete');

    this.setState({
      loading: true
    });

    const params = {
      promo_id: this.state.promo_id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/deletepromo');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      console.log('testb', data);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        notify.show('Promo deleted successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/pages/promos');
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Additional information for discount</h5>
                  </div>
                  <SkuForm initialValues={this.state.data} typeOption={this.state.type} isDisabled={this.state.disabled} onSelectCat={this.onSelectCat} onSubmit={this.submit} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
            <Col md={12} hidden={!this.state.disabled}>
              <button className="btn btn-danger right" onClick={(e) => this.delete()}>Delete Item</button>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(PromoPage);
