import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Brush, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../Panel';
import Config from '../../Config';

class RevenueChart extends PureComponent {
  static propTypes = {
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    completion: PropTypes.func,
    config: PropTypes.object,
    loading: false
  };

  state = {
    title: 'Plot By Date Range',
    data: [],
    dateRange: [new Date().getTime() > new Date('17 OCT 2018').getTime() ? new Date() : new Date('17 OCT 2018'), new Date()],
    updated: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: true
      });
      this.startLoad();
    }, 600);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.startDate !== this.state.dateRange[0] || newProps.endDate !== this.state.dateRange[1]) {
      this.setState({
        dateRange: [
          newProps.startDate, newProps.endDate
        ],
        updated: false
      }, () => {
        this.load();
      });
    }
  }

  getDateItems(revenue) {
    const items = [];
    const incoming = [...revenue];

    let start = this.state.dateRange[0];

    while (start < this.state.dateRange[1]) {
      const date = new Date(start);
      items.push({
        name: `${date.getDate()} ${this.monthNames[date.getMonth()]}`,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0
      });
      start += 24 * 60 * 60 * 1000;
    }

    return items.map((item) => {
      if (incoming.length > 0) {
        if (incoming[0].name === item.name) {
          return incoming.shift();
        }
      }
      return item;
    });
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  calcDateId(time) {
    const date = new Date(time);
    return (date.getFullYear() * 10000) + ((date.getMonth() + 1) * 100) + date.getDate();
  }

  calcDateFromId(id) {
    const year = Math.floor(id / 10000);
    const month = Math.floor((id - (year * 10000)) / 100);
    const day = id - (year * 10000) - (month * 100);
    return `${day} ${this.monthNames[month - 1]}`;
  }

  startLoad = () => {
    if (this.state.updated) {
      this.setState({
        updated: false
      }, () => {
        this.load();
      });
    }
  }

  load = () => {
    this.setState({
      loading: true
    });

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/revenue');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      start_date_id: this.calcDateId(this.state.dateRange[0]),
      end_date_id: this.calcDateId(this.state.dateRange[1])
    };

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          updated: true,
          data: this.getDateItems(body.revenue.map((item) => {
            const cash = item.CASH_SALE ? (item.CASH_SALE / 100).toFixed(2) : 0;
            const cashless = item.CASHLESS_SALE ? (item.CASHLESS_SALE / 100).toFixed(2) : 0;
            const online = item.ONLINE_ORDER ? (item.ONLINE_ORDER / 100).toFixed(2) : 0;
            const wholesale = item.WHOLESALE_ORDER ? (item.WHOLESALE_ORDER / 100).toFixed(2) : 0;
            const tips = item.TIPS ? (item.TIPS / 100).toFixed(2) : 0;
            const refund = item.REFUND ? (item.REFUND / 100).toFixed(2) : 0;
            const cost = item.COST ? (item.COST / 100).toFixed(2) : 0;
            return {
              name: this.calcDateFromId(item.date_id),
              a: cash,
              b: cashless,
              c: online,
              d: wholesale,
              e: tips,
              f: refund,
              g: ((parseFloat(cash) + parseFloat(cashless) + parseFloat(online) + parseFloat(wholesale) + parseFloat(tips)) - parseFloat(refund)).toFixed(2),
              h: cost
            };
          }))
        }, () => {
          console.log('testccc', body);
          this.props.completion(this.state.data);
        });
      } else {
        console.log('e', body.message);
        this.props.completion();
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <Panel md={12} lg={12} xl={12} title={this.state.title} completed={this.state.updated} loading={this.startLoad} >
        {this.state.data.length > 0
          && <ResponsiveContainer height={480} className="dashboard__area">
            <AreaChart data={this.state.data} margin={{ top: 20, left: -15, bottom: 20 }}>
              <XAxis dataKey="name" tickLine={false} />
              <YAxis tickLine={false} />
              <Tooltip />
              <Legend />
              <CartesianGrid />
              <Brush dataKey="name" height={12} stroke="#70bbfd" fill="#f2f4f7" />
              <Area name="Cash Sales" type="monotone" dataKey="a" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} />
              <Area name="Cashless Sales" type="monotone" dataKey="b" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
              <Area name="Online Sales" type="monotone" dataKey="c" fill="#f39c12" stroke="#f39c12" fillOpacity={0.2} />
              <Area name="Wholesale Sales" type="monotone" dataKey="d" fill="#9b59b6" stroke="#9b59b6" fillOpacity={0.2} />
              <Area name="Tips" type="monotone" dataKey="e" fill="#95a5a6" stroke="#95a5a6" fillOpacity={0.2} />
              <Area name="Refund" type="monotone" dataKey="f" fill="#bdc3c7" stroke="#bdc3c7" fillOpacity={0.2} />
              <Area name="Total Sales" type="monotone" dataKey="g" fill="#e74c3c" stroke="#e74c3c" fillOpacity={0.2} />
            </AreaChart>
          </ResponsiveContainer>
        }
        {this.state.data.length === 0 && !this.state.loading
          && <h5 className="bold-text">No data available for this period</h5>
        }
      </Panel>
    );
  }
}

export default RevenueChart;
