/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Config from '../../Config';

export default class PairItems extends Component {
  static propTypes = {
    config: PropTypes.object,
    data: PropTypes.array,
    id: PropTypes.string,
    load: PropTypes.func,
    history: PropTypes.object
  };

  state = {
    menu: []
  }

  update = () => {
    this.props.load();

    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      inventory_id: this.props.id,
      custom: this.state.menu.filter(item => item.selected).map(item => item.id)
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/pair');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('d', body);
        this.props.history.push('/pages/menu');
      } else {
        console.log('e', body.message);
      }
      this.props.load();
    };

    xhr.send(JSON.stringify(params));
  }

  componentWillReceiveProps(props) {
    this.setState({
      menu: this.state.menu.map((item) => {
        return {
          name: item.name,
          id: item.id,
          selected: props.data.indexOf(item.id) >= 0
        };
      })
    });
  }

  load = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id
    };

    console.log('check', 'load', this.props.data);

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/menuinventory');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          menu: body.menu.map((item) => {
            return {
              name: item.name,
              id: item.inventory_id,
              selected: this.props.data.indexOf(item.inventory_id) >= 0
            };
          }).sort((a, b) => {
            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
          })
        });
        console.log('d', body);
      } else {
        console.log('e', body.message);
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  componentDidMount() {
    this.load();
  }

  select = (index) => {
    const menu = this.state.menu;
    menu[index].selected = !menu[index].selected;
    this.setState({ menu: menu });
  }

  render() {
    return (
      <main>
        <Row>
          {/*<Col md={8} className="card__title">
            <h5 className="bold-text">Stock List</h5>
            <h5 className="subhead">Existing stocks for menu item</h5>
          </Col>
          <Col md={4}>
            <Link className="btn btn-success" to={`/pages/addskuingredient?i=${JSON.stringify(this.state.ingredients)}&s=${this.state.inventory_id}`}>Add Ingredient</Link>
          </Col>*/}
          <Col md={8} className="card__title">
            <h5 className="bold-text">Add-on Option</h5>
            <h5 className="subhead">Select all menu items that can be paired with this</h5>
          </Col>
          <Col md={4}>
            <Button className="btn btn-success" onClick={() => this.update()}>Update</Button>
          </Col>
        </Row>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.state.menu.map((item, i) =>
              (
                <div className="catalog-item-colored" key={i}>
                  <div className="catalog-item__link">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={3}>
                          <input
                            type="checkbox"
                            id={item.id}
                            checked={item.selected}
                            onChange={() => this.select(i)}
                          />
                        </Col>
                        <Col md={9}>
                          <h4 className="catalog-item__title">{item.name}</h4>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    );
  }
}
