import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Range from './Range';

class DoubleSliderType extends PureComponent {
  static propTypes = {
    update: PropTypes.func,
    enabled: PropTypes.bool,
    start: PropTypes.number,
    end: PropTypes.number,
    label: PropTypes.string
  };

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  defaultLowDate() {
    const lowbound = new Date();
    lowbound.setMonth(lowbound.getMonth() - 1);
    let lowboundTime = lowbound.getTime();
    if (lowboundTime < new Date('17 OCT 2018').getTime()) {
      lowboundTime = new Date('17 OCT 2018').getTime();
    }
    return lowboundTime;
  }//[this.defaultLowDate(), new Date().getTime()]

  defaultHighDate() {
    const now = new Date();
    now.setMonth(now.getMonth() + 1);
    return now;
  }

  render() {
    return (
      <Col md={12} lg={12} xs={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Choose Date Range</h5>
              <h5 className="subhead">{this.props.label ? this.props.label : 'Select the period you want to view'}</h5>
            </div>
            <Range
              disabled={!this.props.enabled}
              onChange={this.props.update}
              min={new Date('17 OCT 2018').getTime()}
              max={this.defaultHighDate().getTime()}
              value={[this.props.start, this.props.end]}
              tipFormatter={value => `${new Date(value).getDate()} ${this.monthNames[new Date(value).getMonth()]}`} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default DoubleSliderType;
