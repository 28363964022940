import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import { Link, withRouter } from 'react-router-dom';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import ElementForm from './components/ElementForm';

class ElementPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };
  
  state = {
    loading: false,
    error: '',
    isNew: true,
    data: {},
    id: 'none'
  };
  
  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    if (queries.uid) {
      this.load(queries.uid, queries.name);
    } else {
      this.state.isNew = true;
    }
    this.submit = this.submit.bind(this);
  }

  load = (id, name) => {
    this.setState({
      loading: false,
      error: '',
      isNew: false,
      id: id,
      data: {
        id: id,
        name: name
      }
    });
  }

  submit = (e) => {
    if (!this.state.isNew) return;

    if (e.password.length < 6) {
      this.setState({
        error: 'Password must be more than 5 characters'
      });
      return;
    }

    this.setState({
      loading: true
    });

    const params = {
      branch: this.props.config.user.branches[0].branch_id,
      id: e.id,
      name: e.name,
      password: e.password
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/createelement');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      console.log('testb', body);
      if (data.statusCode === 200) {
        notify.show('Account updated successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/pages/elements');
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Manage Element account</h5>
                  </div>
                  <ElementForm isNew={this.state.isNew} initialValues={this.state.data} onSubmit={this.submit} isDisabled={this.state.loading} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
        
export default withRouter(ElementPage);
