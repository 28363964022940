import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../_layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../login/index';
import DashboardPage from '../dashboard/index';
import SalesPage from '../sales/index';
import OrderPage from '../submitorder/index';
import CheckoutPage from '../checkout/index';
import OrderConfirmPage from '../orderconfirm';
import OrdersPage from '../orders';
import ViewOrderPage from '../vieworder';
import IngredientsPage from '../ingredients';
import NewIngredientPage from '../ingredient_add';
import MenuPage from '../menu';
import IngredientPage from '../ingredient';
import SkuPage from '../sku';
import SkuIngredientPage from '../sku_ingredient';
import AddStockPage from '../addstock';
import AddSkuPage from '../sku_add';
import InventoryOverviewPage from '../viewingredients';
import CustomMenuPage from '../customs';
import CategoriesPage from '../categories';
import CategoryPage from '../category';
import ElementsPage from '../elements';
import ElementPage from '../element';
import Register from '../register';
import CustomPage from '../custom';
import AdvOrdersPage from '../advorders';
import ViewAdvOrderPage from '../advorder';
import TimeDashboardPage from '../timedashboard';
import PromosPage from '../promos';
import PromoPage from '../promo';
import ReceiptsPage from '../receipts';
import ReceiptPage from '../receipt';
import StaffOrdersPage from '../stafforders';
import HitchesPage from '../hitches';

class Pages extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    notification: PropTypes.array,
    removeNotification: PropTypes.func,
    token: PropTypes.string
  };
  
  render() {
    return (
      <Switch>
        <Route path="/pages/dashboard" render={(routeProps) => <DashboardPage config={this.props.config} updateConfig={this.props.updateConfig} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/time" render={(routeProps) => <TimeDashboardPage config={this.props.config} updateConfig={this.props.updateConfig} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/orders" render={(routeProps) => <OrdersPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/sales" render={(routeProps) => <SalesPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/order" render={(routeProps) => <OrderPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/menu" render={(routeProps) => <MenuPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/custommenu" render={(routeProps) => <CustomMenuPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/checkout" render={(routeProps) => <CheckoutPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/order-confirm" component={OrderConfirmPage} />
        <Route path="/pages/view" render={(routeProps) => <ViewOrderPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/ingredients" render={(routeProps) => <IngredientsPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/newingredient" render={(routeProps) => <NewIngredientPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/ingredient" render={(routeProps) => <IngredientPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/sku" render={(routeProps) => <SkuPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/fullviewingredient" render={(routeProps) => <InventoryOverviewPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/addskuingredient" render={(routeProps) => <SkuIngredientPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/stock" render={(routeProps) => <AddStockPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/addsku" render={(routeProps) => <AddSkuPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/categories" render={(routeProps) => <CategoriesPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/category" render={(routeProps) => <CategoryPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/elements" render={(routeProps) => <ElementsPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/element" render={(routeProps) => <ElementPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/receipts" render={(routeProps) => <ReceiptsPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/receipt" render={(routeProps) => <ReceiptPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/custom" render={(routeProps) => <CustomPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/advorders" render={(routeProps) => <AdvOrdersPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/hitches" render={(routeProps) => <HitchesPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/viewadvorder" render={(routeProps) => <ViewAdvOrderPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/promos" render={(routeProps) => <PromosPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/promo" render={(routeProps) => <PromoPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/stafforders" render={(routeProps) => <StaffOrdersPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        
      </Switch>
    );
  }
}

class WrappedRoutes extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    notification: PropTypes.array,
    removeNotification: PropTypes.func,
    token: PropTypes.string
  };

  render() {
    return (
      <div>
        <Layout config={this.props.config} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} />
        <div className="container__wrap">
          <Route path="/pages" render={(routeProps) => <Pages config={this.props.config} token={this.props.token} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} location={routeProps.location} />} />
        </div>
      </div>
    );
  }
}

class Router extends Component {
  render() {
    return (
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" render={(routeProps) => <LogIn update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/login" render={(routeProps) => <LogIn update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/register" render={(routeProps) => <Register update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route path="/" render={(routeProps) => <WrappedRoutes config={this.props.config} token={this.props.token} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} location={routeProps.location} />} />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}

Router.propTypes = {
  updateConfig: PropTypes.func,
  config: PropTypes.object,
  updateBasket: PropTypes.func,
  basket: PropTypes.array,
  notification: PropTypes.array,
  removeNotification: PropTypes.func,
  token: PropTypes.string
};

export default Router;
