import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import CatalogItem from './components/CatalogItem';

class SkuIngredientPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object
  };

  constructor() {
    super();
    this.saved = this.saved.bind(this);
  }

  state = {
    sku_id: '',
    ingredients: [],
    enable: true
  }

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.state = {
      sku_id: queries.s,
      ingredients: JSON.parse(queries.i)
    };
  }

  saved = () => {
    this.setState({
      enable: true
    });
  }

  save = () => {
    console.log('tt', this.state.sku_id);

    this.setState({
      enable: false
    });

    this.catalogRef.save(this.state.sku_id);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Menu Item Ingredients Management</h3>
            <h3 className="page-subhead subhead">Select or deselect ingredients from your menu item
            </h3>
          </Col>
          <Col md={2} hidden={this.state.loading}>
            <button className="btn btn-success" disabled={!this.state.enable} onClick={() => this.save()}>Save</button>
          </Col>
        </Row>
        <Row>
          <CatalogItem ref={(ref) => { this.catalogRef = ref; }} saved={this.saved} sku={this.state.sku_id} ingredients={this.state.ingredients} config={this.props.config} />
        </Row>
      </Container>
    );
  }
}

export default SkuIngredientPage;
