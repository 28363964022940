import React from 'react';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import UserIcon from 'mdi-react/FoodIcon';
import PriceIcon from 'mdi-react/CounterIcon';
import DescIcon from 'mdi-react/NotesIcon';
import GiftIcon from 'mdi-react/GiftIcon';
import CashIcon from 'mdi-react/CashIcon';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import renderCheckBoxField from '../../Checkbox';

const parse = (value) => (value === undefined ? 0 : parseFloat(value));

const AddSkuForm = ({ handleChange, handleSubmit, value, isDisabled, categories, onSelectCat }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Category</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <select className="form__form-group-select" onChange={onSelectCat}>
          {
            categories.map((item) => (
              <option key={item.value} value={item.value}>{item.label}</option>
            ))
          }
        </select>
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <DescIcon />
        </div>
        <Field
          name="description"
          component="input"
          type="text"
          placeholder="Description (optional)"
          disabled={isDisabled}
        />
      </div>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <span className="form__form-group-label">Shop Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="shop_price"
              component="input"
              type="number"
              placeholder="Shop Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Online Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="price"
              component="input"
              type="number"
              placeholder="Online Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Wholesale Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="whole_price"
              component="input"
              type="number"
              placeholder="Wholesale Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <span className="form__form-group-label">Shop Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="shop_points"
              component="input"
              type="number"
              placeholder="Shop Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Online Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="points"
              component="input"
              type="number"
              placeholder="Online Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Wholesale Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="whole_points"
              component="input"
              type="number"
              placeholder="Wholesale Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_shop"
              component={renderCheckBoxField}
              label="Allow Shop"
              className="colored-click"
              defaultChecked={true}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_online"
              component={renderCheckBoxField}
              label="Allow Online"
              className="colored-click"
              defaultChecked={true}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_wholesale"
              component={renderCheckBoxField}
              label="Allow Wholesale"
              className="colored-click"
              defaultChecked={true}
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Minimum Production Quantity (MOQ)</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <Field
          name="moq"
          component="input"
          type="number"
          placeholder="Minimum quantity to produce based on ingredients amount defined"
          disabled={isDisabled}
          parse={parse}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Notes</span>
      <div className="form__form-group-field">
        <Field
          name="note"
          component="textarea"
          type="text"
          rows="10"
          placeholder="Notes (optional)"
          disabled={isDisabled}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
      <Button type="button" disabled={isDisabled}>Reset</Button>
    </ButtonToolbar>
  </form>
);

AddSkuForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSelectCat: PropTypes.func,
  categories: PropTypes.array,
  value: PropTypes.object,
  isDisabled: PropTypes.bool
};

export default reduxForm({ form: 'addskuform' })(AddSkuForm);
