/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import QtyIcon from 'mdi-react/CartPlusIcon';
import Config from '../../Config';

export default class Catalog extends Component {
  static propTypes = {
    config: PropTypes.object,
    ingredients: PropTypes.array,
    history: PropTypes.object,
    saved: PropTypes.func,
    sku: PropTypes.any
  };

  state = {
    ingredients: []
  };

  componentDidMount() {
    notify.show('Loading, please wait', 'custom', 5000, {
      background: '#343a40',
      text: '#ddae71'
    });

    setTimeout(() => {
      this.load();
    }, 600);
  }

  load = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/ingredients');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    console.log('config', this.props.config);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          ingredients: body.ingredients.map((item) => {
            let found = {};
            found = this.props.ingredients.find((f) => {
              return f.id === item.inventory_id;
            });
            return {
              title: item.name,
              id: item.inventory_id,
              unit: item.unit,
              quantity: found ? found.quantity : 0
            };
          }).sort((a, b) => {
            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
          })
        });
        console.log('d', body);
      } else {
        console.log('e', body.message);
      }
    };

    xhr.send(JSON.stringify(params));
  }

  save = (sku) => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      id: sku,
      ingredients: this.state.ingredients.filter((item) => {
        return item.quantity > 0;
      }).map((item) => {
        return {
          id: item.id,
          qty: item.quantity
        };
      })
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/updateskuingredient');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    console.log('config', this.props.config);
    console.log('param', params);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('d', body);
        notify.show('Ingredients saved', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });
        this.props.saved();

        setTimeout(() => {
          window.location = '/pages/menu';
        }, 600);
      } else {
        console.log('e', body.message);
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  updateQuantity = (e, i) => {
    this.setState({
      ingredients: this.state.ingredients.map((item, index) => {
        const edit = item;
        if (index === i) {
          edit.quantity = e.target.value === '' ? 0 : parseFloat(e.target.value);
        }
        return edit;
      })
    });
  }

  render() {
    return (
      <main>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.state.ingredients.map((item, i) =>
              (
                <div className={`catalog-item${item.quantity > 0 ? '-colored-norm' : '' }`} key={i} >
                  <div className="catalog-item__link-colored-norm">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={12}>
                          <h4 className="catalog-item__title">{item.title}</h4>
                          <br />
                          <form className="form">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <div className={`form__form-group-button ${item.quantity > 0 ? 'active' : '' }`}>
                                  <QtyIcon />
                                </div>
                                <input
                                  name="name"
                                  component="input"
                                  type="number"
                                  step="0.01"
                                  placeholder={`Quantity in ${item.unit}`}
                                  value={item.quantity > 0 ? item.quantity : '' }
                                  onChange={e => this.updateQuantity(e, i)}
                                  required
                                />
                              </div>
                            </div>
                          </form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    );
  }
}
