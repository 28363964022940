import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class CatalogItem extends Component {
  static propTypes = {
    config: PropTypes.object,
    update: PropTypes.func,
    categories: PropTypes.array
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.props.categories.map((item, i) =>
              (
                <Link className="catalog-item clickable" key={item.id} to={`/pages/category?id=${item.id}&name=${item.name}&index=${i}${item.visible ? '&visible=true' : '' }`} >
                  <div className="catalog-item__link">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={9}>
                          <h4 className="catalog-item__title">{item.name}</h4>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </Col>
    );
  }
}

export default CatalogItem;
