import React, { Component } from 'react';
import { PieChart, Pie } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../Panel';

class BudgetStatistic extends Component {
  static propTypes = {
    total: PropTypes.number,
    cost: PropTypes.number,
    data: PropTypes.object
  };

  render() {
    return (
      <Panel md={12} lg={6} xl={6} title="Revenue This Month">
        <div className="dashboard__stat dashboard__stat--budget">
          <div className="dashboard__stat-main">
            <p className="dashboard__stat-main-title">Total Revenue</p>
            <p className="dashboard__stat-main-number">${(this.props.total - this.props.cost).toFixed(2)}</p>
            <hr />
          </div>
          <div className="dashboard__stat-chart">
            <PieChart height={120} width={120}>
              <Pie data={this.props.data} dataKey="value" cx={55} cy={55} innerRadius={50} outerRadius={60} />
            </PieChart>
            <p className="dashboard__stat-label">$</p>
          </div>
          <div className="dashboard__stat-data">
            <div>
              <p className="dashboard__stat-data-number">${this.props.cost.toFixed(2)}</p>
              <p style={{ color: '#ff4861' }}>Ingredient Cost</p>
            </div>
            <div>
              <p className="dashboard__stat-data-number">${this.props.total.toFixed(2)}</p>
              <p style={{ color: '#4ce1b6' }}>Net Income</p>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

export default BudgetStatistic;
