import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import AddSkuForm from './components/AddSkuForm';
import LoadingScreen from '../../shared/components/loading';

class AddSkuPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
    this.onSelectCat = this.onSelectCat.bind(this);
  }

  state = {
    loading: true,
    branch_id: '',
    inventory_id: '',
    categories: [],
    category: '',
    data: {
      currency: 'SGD',
      unit: 'ea'
    },
    disabled: false,
    error: ''
  };

  componentDidMount() {
    setTimeout(() => {
      this.load();
    }, 600);
  }

  onSelectCat = (e) => {
    console.log('select', e.target.value);
    this.setState({
      category: e.target.value
    });
  }

  load = () => {
    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/getcategories');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('d', body);
        this.setState({
          categories: body.categories.map((item) => {
            return {
              label: item.name,
              value: item.id
            };
          }),
          category: body.categories.length > 0 ? body.categories[0].id : ''
        }, () => {
          console.log('test', this.state);
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  save = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      data: this.state.data
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/createsku');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      console.log('test', xhr.responseText);
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        notify.show('Created successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });
        this.props.history.push(`/pages/sku?d=${body.id}&b=${this.props.config.user.branches[0].branch_id}`);
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false, disabled: false, isQtyDisabled: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  submit = (e) => {
    this.setState({
      data: {
        name: e.name,
        description: e.description ? e.description : ' ',
        moq: e.moq,
        price: e.price * 100,
        points: e.points,
        shop_price: e.shop_price * 100,
        shop_points: e.shop_points,
        whole_price: e.whole_price * 100,
        whole_points: e.whole_points,
        show_shop: e.show_shop,
        show_online: e.show_online,
        show_wholesale: e.show_wholesale,
        group_price: [],
        unit: 'ea',
        currency: 'SGD',
        note: e.note ? e.note : 'None',
        category: this.state.category
      },
      disabled: true,
      loading: true
    }, () => {
      console.log('submit', this.state.data);
      this.save();
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Additional information for menu item</h5>
                  </div>
                  <AddSkuForm initialValues={this.state.data} categories={this.state.categories} onSubmit={this.submit} isDisabled={this.state.disabled} onSelectCat={this.onSelectCat} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(AddSkuPage);
