import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CatalogItem from './components/CatalogItem';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class OrderPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array
  };

  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.updateBasket([]);
    }, 600);
  }

  loadComplete = () => {
    this.setState({ loading: false });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">Wholesale Order</h3>
              <h3 className="page-subhead subhead">Enter the quantity for the order and checkout
              </h3>
            </Col>
            <Col md={2} hidden={this.state.loading}>
              <Link className="btn btn-success" to="/pages/checkout">Checkout</Link>
            </Col>
          </Row>
          <Row>
            <CatalogItem complete={this.loadComplete} config={this.props.config} basket={this.props.basket} update={this.props.updateBasket} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default OrderPage;
