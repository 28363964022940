/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';
import Pagination from '../../Pagination';

const MoneyFormatter = ({ value }) => (
  <div>
      ${value}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/view?track=${JSON.parse(value).branch_id}&t=${JSON.parse(value).timestamp}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value === 'Collected' ? <span className="badge badge-success">Collected</span> :
  <span className="badge badge-disabled">Pending</span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const TypeFormatter = ({ value }) => (
  value === 'WHOLESALE_ORDER' ? <span className="badge badge-success">Wholesale</span> :
    value === 'ONLINE_ORDER' ? <span className="badge badge-primary">Online</span> :
      value === 'CASHLESS_SALE' ? <span className="badge badge-danger">Cashless</span> :
      <span className="badge badge-warning">Cash</span>
);

TypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class OrdersListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'id',
        name: 'ID',
        sortable: true,
      },
      {
        key: 'date',
        name: 'Date',
        sortable: true,
      },
      {
        key: 'name',
        name: 'Customer Name',
        sortable: true,
      },
      {
        key: 'price',
        name: 'Price',
        sortable: true,
        formatter: MoneyFormatter,
      },
      {
        key: 'quantity',
        name: 'Items',
        sortable: true,
      },
      {
        key: 'type',
        name: 'Type',
        sortable: true,
        formatter: TypeFormatter,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        formatter: StatusFormatter,
      },
      {
        key: 'tracker',
        name: ' ',
        sortable: false,
        formatter: ViewFormatter
      },
    ];

    this.state = {
      pageOfItems: [],
    };
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Orders list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
