import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Alert, Button, ButtonToolbar, Row, Col } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import UserIcon from 'mdi-react/UserIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import ShopIcon from 'mdi-react/ShopIcon';
import AddressIcon from 'mdi-react/AddressMarkerIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import PropTypes from 'prop-types';

class RegisterForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    return (
      <main>
        <form className="form" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Merchant</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <UserIcon />
              </div>
              <Field
                name="merchant_name"
                component="input"
                type="text"
                placeholder="Merchant Name"
                disabled={this.props.isDisabled}
                required
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Branch</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <ShopIcon />
              </div>
              <Field
                name="branch_name"
                component="input"
                type="text"
                placeholder="Branch Name"
                disabled={this.props.isDisabled}
                required
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Address</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AddressIcon />
              </div>
              <Field
                name="address"
                component="input"
                type="text"
                placeholder="Branch Address"
                disabled={this.props.isDisabled}
                required
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Phone</span>
            <Row>
              <Col md={4}>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <PhoneIcon />
                  </div>
                  <Field
                    name="code"
                    component="input"
                    type="text"
                    placeholder="Code"
                    disabled={this.props.isDisabled}
                    required
                  />
                </div>
              </Col>
              <Col md={8}>
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component="input"
                    type="tel"
                    placeholder="Mobile Number"
                    disabled={this.props.isDisabled}
                    required
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <EmailIcon />
              </div>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Email Address"
                disabled={this.props.isDisabled}
                required
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component="input"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password"
                disabled={this.props.isDisabled}
                required
              />
              <button
                className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
              ><EyeIcon />
              </button>
            </div>
          </div>
            
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit" disabled={this.props.isDisabled}>Create Account</Button>
          </ButtonToolbar>
        </form>
        
      </main>
    );
  }
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.any,
  isDisabled: PropTypes.bool
};

export default reduxForm({ form: 'registerform' })(RegisterForm);
