import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import RegisterForm from './components/RegisterForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class Register extends Component {
  state = {
    loading: false,
    data: {
      code: '+65'
    },
    error: ''
  };

  componentDidMount() {
    this.submit = this.submit.bind(this);
  }

  start() {
    this.setState({
      loading: true
    });
  }

  submit = (e) => {
    console.log('test', e);

    if (!this.isValidPassword(e.password)) {
      this.setState({ error: 'Your password should have at least 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum 8 characters' });
      return;
    }

    if (!this.isValidCountry(e.code)) {
      this.setState({ error: 'Please enter valid country code for your phone number' });
      return;
    }

    this.setState({
      loading: true,
      error: ''
    });

    const params = {
      merchant_name: e.merchant_name,
      branch_name: e.branch_name,
      address: e.address,
      phone: e.code + e.phone,
      password: e.password,
      email: e.email
    };

    const xhr = this.createCORSRequest('POST', 'https://4tbz22ozn7.execute-api.ap-southeast-1.amazonaws.com/prod/create');
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      console.log('testb', body);
      if (data.statusCode === 200) {
        notify.show('Account created successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/login');
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  isValidPassword(password) {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

    if (regex.test(password)) return true;

    return false;
  }

  isValidCountry(code) {
    if (code.slice(0, 1) !== '+') return false;

    if (!/^-{0,1}\d+$/.test(code.substring(1))) return false;

    const length = code.substring(1).length;
    if (length < 1 || length > 3) return false;

    return true;
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="center">
                <img className="logo" src={Logo} alt="pretzel-logo" />
              </div>
              <div className="account__head">
                <h3 className="account__title">Welcome to
                  <span className="account__logo"> Pretzel
                    <span className="account__logo-accent"> Merchants</span>
                  </span>
                </h3>
                <h4 className="account__subhead subhead">Manage your business easily</h4>
              </div>
              <Alert color="danger" className="bold-text" isOpen={this.state.error}>
                <div className="alert__content">
                  {this.state.error}
                </div>
              </Alert>
              <RegisterForm initialValues={this.state.data} onSubmit={this.submit} isDisabled={this.state.loading} enableReinitialize />
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

Register.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(Register);

