import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="home" route="/pages/dashboard" onClick={this.hideSidebar} />
          <SidebarLink title="Element" icon="license" route="/pages/elements" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Sales Chart" icon="chart-bars" route="/pages/sales" onClick={this.hideSidebar} />
          <SidebarLink title="Transactions" icon="book" route="/pages/orders" onClick={this.hideSidebar} />
          <SidebarLink title="Advance Orders" icon="book" route="/pages/advorders" onClick={this.hideSidebar} />
          <SidebarLink title="Wholesale Order" icon="cart" route="/pages/order" onClick={this.hideSidebar} />
          <SidebarLink title="Hitches List" icon="cart" route="/pages/hitches" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Inventory Overview" icon="cloud" route="/pages/fullviewingredient" onClick={this.hideSidebar} />
          <SidebarLink title="Ingredients List" icon="diamond" route="/pages/ingredients" onClick={this.hideSidebar} />
          <SidebarLink title="Categories" icon="menu-circle" route="/pages/categories" onClick={this.hideSidebar} />
          <SidebarLink title="Menu List" icon="coffee-cup" route="/pages/menu" onClick={this.hideSidebar} />
          <SidebarLink title="Custom Menu" icon="bookmark" route="/pages/custommenu" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Promos & Discounts" icon="tag" route="/pages/promos" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Staff Receipts" icon="book" route="/pages/receipts" onClick={this.hideSidebar} />
          <SidebarLink title="Staff Deliveries" icon="cart" route="/pages/stafforders" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
