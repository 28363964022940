import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EditTable from '../../EditTable';

export default class EditableTable extends PureComponent {
  static propTypes = {
    stock: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'id',
        name: 'ID',
        editable: false,
      },
      {
        key: 'name',
        name: 'Last Created',
        editable: false,
      },
      {
        key: 'quantity',
        name: 'Quantity',
        editable: true,
      },
      {
        key: 'description',
        name: 'Description',
        editable: true,
      },
    ];
  }

  render() {
    return (
      <EditTable heads={this.heads} rows={this.props.stock} />
    );
  }
}
