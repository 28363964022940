import React from 'react';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import ContactIcon from 'mdi-react/ScaleBalanceIcon';
import UserIcon from 'mdi-react/FoodIcon';
import PriceIcon from 'mdi-react/CounterIcon';
import DescIcon from 'mdi-react/NotesIcon';
import CashIcon from 'mdi-react/CashIcon';
import GiftIcon from 'mdi-react/GiftIcon';
import TrashIcon from 'mdi-react/EditIcon';
import AddIcon from 'mdi-react/AddIcon';
import ClearIcon from 'mdi-react/TrashIcon';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../Checkbox';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));

const SkuForm = ({ handleChange, handleSubmit, value, isDisabled, editQty, isQtyDisabled, max, change, categories, onSelectCat }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="disabled"
          component={renderCheckBoxField}
          label="Disable this item"
          className="colored-click"
          defaultChecked={false}
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={isDisabled}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Category</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <select className="form__form-group-select" onChange={onSelectCat}>
          {
            categories.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))
          }
        </select>
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <DescIcon />
        </div>
        <Field
          name="description"
          component="input"
          type="text"
          placeholder="Description (optional)"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <span className="form__form-group-label">Shop Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="shop_price"
              component="input"
              type="number"
              placeholder="Shop Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Online Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="price"
              component="input"
              type="number"
              placeholder="Online Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Wholesale Price</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="whole_price"
              component="input"
              type="number"
              placeholder="Wholesale Price"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <span className="form__form-group-label">Shop Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="shop_points"
              component="input"
              type="number"
              placeholder="Shop Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Online Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="points"
              component="input"
              type="number"
              placeholder="Online Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Wholesale Points</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <GiftIcon />
            </div>
            <Field
              name="whole_points"
              component="input"
              type="number"
              placeholder="Wholesale Points"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <span className="form__form-group-label">Quantity less orders</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <PriceIcon />
            </div>
            <Field
              name="quantity"
              component="input"
              type="number"
              placeholder="Quantity"
              parse={parse}
              disabled={isQtyDisabled}
              required
            />
            <button
              className="form__form-group-danger active"
              onClick={(e) => {
                e.preventDefault();
                change('quantity', 0);
              }}
              title="Clear all remaining quantity"
              disabled={isDisabled}
            ><ClearIcon />
            </button>
            <button
              className="form__form-group-button active"
              onClick={(e) => editQty(e)}
              title="Edit quantity without consuming ingredients"
              disabled={isDisabled}
            ><TrashIcon />
            </button>
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Add Quantity</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AddIcon />
            </div>
            <Field
              name="add"
              component="input"
              type="number"
              placeholder="Quantity"
              parse={parse}
              max={max}
              required
            />
          </div>
        </Col>
        <Col md={4}>
          <span className="form__form-group-label">Today&#39;s Orders</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <PriceIcon />
            </div>
            <Field
              name="order"
              component="input"
              type="number"
              placeholder="Order Quantity"
              parse={parse}
              disabled
              required
            />
          </div>
        </Col>
      </Row>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Minimum Production Quantity (MOQ)</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <Field
          name="moq"
          component="input"
          type="number"
          placeholder="Minimum quantity to produce based on ingredients amount defined"
          disabled={isDisabled}
          parse={parse}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Unit</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="unit"
          component="input"
          type="tel"
          placeholder="Unit"
          disabled
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <Row>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_shop"
              component={renderCheckBoxField}
              label="Allow Shop"
              className="colored-click"
              defaultChecked={false}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_online"
              component={renderCheckBoxField}
              label="Allow Online"
              className="colored-click"
              defaultChecked={false}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form__form-group-field">
            <Field
              name="show_wholesale"
              component={renderCheckBoxField}
              label="Allow Wholesale"
              className="colored-click"
              defaultChecked={false}
            />
          </div>
        </Col>
      </Row>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Notes</span>
      <div className="form__form-group-field">
        <Field
          name="note"
          component="textarea"
          type="text"
          rows="10"
          placeholder="Notes (optional)"
          disabled={isDisabled}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Photo</span>
      <div className="form__form-group-field">
        <Field
          name="img"
          component={renderDropZoneField}
        />
      </div>
    </div>
    
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
      <Button type="button" disabled={isDisabled}>Reset</Button>
    </ButtonToolbar>
  </form>
);

SkuForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  isQtyDisabled: PropTypes.bool,
  editQty: PropTypes.func,
  max: PropTypes.number,
  categories: PropTypes.array,
  onSelectCat: PropTypes.func
};

export default reduxForm({ form: 'skuform' })(SkuForm);
