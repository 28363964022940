import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ProductItems from './ProductItems';

class CatalogItem extends Component {
  static propTypes = {
    config: PropTypes.object,
    ingredients: PropTypes.array,
    saved: PropTypes.func
  };

  save = (sku) => {
    this.listRef.save(sku);
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <ProductItems ref={(ref) => { this.listRef = ref; }} saved={this.props.saved} config={this.props.config} ingredients={this.props.ingredients} />
      </Col>
    );
  }
}

export default CatalogItem;
