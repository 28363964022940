import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import AWS from 'aws-sdk';
import { notify } from 'react-notify-toast';
import Config from '../Config';
import CatalogItem from './components/CatalogItem';
import SkuForm from './components/SkuForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import EditableTable from './components/EditableTable';
import PairItems from './components/PairItems';

class SkuPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
    this.edit = this.edit.bind(this);
  }

  state = {
    loading: true,
    category: '',
    categories: [],
    branch_id: '',
    inventory_id: '',
    name: '',
    note: '',
    quantity: 0,
    ingredients: [],
    unit: '',
    disabled: false,
    max: 0,
    qtyDisabled: true,
    showPopup: false,
    error: '',
    imageUrl: '',
    pairs: []
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      this.load(queries.d, queries.b);
    }, 600);

    this.onSelectCat = this.onSelectCat.bind(this);
  }

  onSelectCat = (e) => {
    this.setState({
      category: e.target.value
    });
  }

  max = (ingredients) => {
    if (!ingredients) return this.state.max;

    let maximum = 0;
    for (let i = 0; i < ingredients.length; i++) {
      const amt = Math.floor(ingredients[i].quantity / ingredients[i].req);
      if (maximum === 0 || maximum > amt) {
        maximum = amt;
      }
    }
    return maximum;
  }

  load = (inventoryId, branchId) => {
    this.setState({
      branch_id: branchId,
      inventory_id: inventoryId
    });

    const params = {
      branch_id: branchId,
      inventory_id: inventoryId
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/getinventory');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('check', 0);
        console.log('check', body.inventory);
        this.setState({ data: {
          add: 0,
          name: body.inventory.name,
          note: body.inventory.note,
          quantity: body.inventory.quantity - body.upcoming,
          unit: body.inventory.unit,
          category: body.inventory.category,
          currency: body.inventory.currency,
          description: body.inventory.description,
          points: body.inventory.points,
          price: body.inventory.price / 100,
          shop_points: body.inventory.shop_points,
          shop_price: body.inventory.shop_price / 100,
          whole_points: body.inventory.whole_points,
          whole_price: body.inventory.whole_price / 100,
          show_online: body.inventory.show_online,
          show_shop: body.inventory.show_shop,
          show_wholesale: body.inventory.show_wholesale,
          group_price: body.inventory.group_price ? body.inventory.group_price : [],
          moq: body.inventory.moq,
          order: body.upcoming,
          ingredients: body.inventory.ingredients,
          disabled: body.inventory.disabled
        },
        pairs: body.inventory.custom ? body.inventory.custom : [],
        ingredients: body.inventory.ingredients,
        imageUrl: body.inventory.img,
        max: this.max(body.inventory.ingredients),
        categories: body.categories.sort((x, y) => {
          return x.id === body.inventory.category ? -1 : y.id === body.inventory.category ? 1 : 0;
        }),
        category: body.inventory.category
        });
        console.log('cats', body.categories);
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  delete = () => {
    console.log('delete');

    this.setState({
      loading: true
    });

    const params = {
      id: this.state.inventory_id,
      type: 'SKU',
      branch: this.state.branch_id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/delete');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      console.log('testb', data);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        notify.show('Item deleted successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/pages/menu');
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  update = () => {
    const updateData = this.state.data;
    updateData.price *= 100;
    updateData.shop_price *= 100;
    updateData.whole_price *= 100;
    updateData.category = this.state.category;

    if (this.state.imageUrl) updateData.img = this.state.imageUrl;

    const params = {
      branch_id: this.state.branch_id,
      inventory_id: this.state.inventory_id,
      data: updateData
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/sku');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.load(this.state.inventory_id, this.state.branch_id);

        notify.show('Updated successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false, disabled: false, isQtyDisabled: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  edit = (e) => {
    e.preventDefault();
    this.setState({ qtyDisabled: false });
  }

  toggleLoad = () => {
    this.setState({ loading: !this.state.loading, error: '' });
  }

  submit = (e) => {
    this.setState({ loading: true, error: '' });

    if (e.img && e.img.length > 0) {
      AWS.config.update({
        accessKeyId: 'AKIAJ2WDGPJLIST7H4CQ',
        secretAccessKey: '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',
        region: 'ap-southeast-1',
      });
      
      const s3 = new AWS.S3({ params: { Bucket: 'pretzel' } });

      const reader = new FileReader();
      reader.readAsDataURL(e.img[0]);
      reader.onload = () => {
        const bin = reader.result;
        let regex = '';
        regex = /^data:image\/\w+;base64,/;
        const buf = new Buffer(bin.replace(regex, ''), 'base64');
        const key = `${this.state.branch_id}sku${this.state.inventory_id}.${e.img[0].type.split('/')[1]}`;

        const data = {
          Key: key,
          Body: buf,
          ContentEncoding: 'base64',
          ContentType: e.img[0].type,
          ACL: 'public-read'
        };

        s3.putObject(data, (err, d) => {
          if (err) {
            console.log('err', err.message);
            this.setState({ error: err.message, loading: false });
          } else {
            this.setState({ data: e, disabled: true, isQtyDisabled: true, loading: true, imageUrl: `https://s3-ap-southeast-1.amazonaws.com/pretzel/${key}` }, () => {
              this.update();
            });
          }
        });
      };

      reader.onerror = (error) => {
        console.log('error', error);
        this.setState({ error: error, loading: false });
      };
    } else {
      this.setState({ data: e, disabled: true, isQtyDisabled: true, loading: true }, () => {
        this.update();
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={6}>
              <Card>
                <CardBody>
                  {/*<CatalogItem data={this.state.ingredients} config={this.props.config} />*/}
                  <PairItems history={this.props.history} load={this.toggleLoad} id={this.state.inventory_id} data={this.state.pairs} config={this.props.config} />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Additional information for menu item</h5>
                  </div>
                  <SkuForm initialValues={this.state.data} categories={this.state.categories} onSubmit={this.submit} max={this.state.max} isDisabled={this.state.disabled} isQtyDisabled={this.state.qtyDisabled} editQty={this.edit} onSelectCat={this.onSelectCat} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
            <Col md={12}>
              <button className="btn btn-danger right" onClick={(e) => this.delete()}>Delete Item</button>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(SkuPage);
