import React from 'react';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import ContactIcon from 'mdi-react/ScaleBalanceIcon';
import ClockIcon from 'mdi-react/CalendarBlankIcon';
import CashIcon from 'mdi-react/CashIcon';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import renderDatePickerField from '../../DatePicker';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));
const normPrice = (value) => (value === undefined ? 0 : parseFloat(value));

const StockForm = ({ reset, handleChange, handleSubmit, value, isDisabled, onReset }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Expiry Date</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ClockIcon />
        </div>
        <Field
          name="expiry"
          component={renderDatePickerField}
          type="date"
          placeholder="Expiry Date"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Purchase Date</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ClockIcon />
        </div>
        <Field
          name="purchase"
          component={renderDatePickerField}
          type="date"
          placeholder="Purchase Date"
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Purchase Price</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <CashIcon />
        </div>
        <Field
          name="price"
          component="input"
          type="number"
          step="0.01"
          placeholder="Price"
          disabled={isDisabled}
          normalize={normPrice}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Quantity</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <CashIcon />
        </div>
        <Field
          name="quantity"
          component="input"
          type="number"
          placeholder="Quantity"
          disabled={isDisabled}
          parse={parse}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Unit</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="unit"
          component="input"
          type="text"
          placeholder="Unit"
          disabled
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
      <Button 
        type="button" 
        disabled={isDisabled} 
        onClick={(e) => {
          reset();
          onReset();
        }}>Reset</Button>
    </ButtonToolbar>
  </form>
);

StockForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  addQty: PropTypes.func,
  removeQty: PropTypes.func
};

export default reduxForm({ form: 'stockform' })(StockForm);
