import React, { Component } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { WithContext as ReactTags } from 'react-tag-input';

class CatalogItem extends Component {
  static propTypes = {
    config: PropTypes.object,
    item: PropTypes.object,
    itemAddOption: PropTypes.func,
    itemDeleteOption: PropTypes.func,
    itemChecked: PropTypes.func,
    suggestions: PropTypes.array,
    delimiters: PropTypes.array
  };

  componentDidMount() {
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleDelete = (i) => {
    this.props.itemDeleteOption(this.props.item.sku_id, i);
  }

  handleAddition = (tag) => {
    this.props.itemAddOption(this.props.item.sku_id, tag);
  }

  handleCheck = (e) => {
    this.props.itemChecked(this.props.item.sku_id, !this.props.item.exists);
  }

  render() {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col md={12} key={this.props.item.sku_id}>
              <input type="checkbox" name={this.props.item.name} value={this.props.item.sku_id} checked={this.props.item.exists} onChange={(e) => this.handleCheck(e)} />{this.props.item.name}
              <br /><br />
              <ReactTags 
                tags={this.props.item.tags}
                suggestions={this.props.suggestions}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                delimiters={this.props.delimiters}
                placeholder="Add option" />
            </Col>
            <br /><br /><br /><br /><br />
            <hr />
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default CatalogItem;
