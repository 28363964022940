import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

class DropZoneField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
    file: {}
  };

  static defaultProps = {
    customHeight: false,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value && newProps.value.length > 0 && newProps.value[0] instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(newProps.value[0]);
      reader.onload = () => {
        const bin = reader.result;
        this.setState({ file: bin });
      };
    }
  }

  removeFile(index, e) {
    e.preventDefault();
    this.props.onChange(this.props.value.filter((val, i) => i !== index));
  }

  render() {
    const files = this.props.value;

    return (
      <div className={`dropzone dropzone--single${this.props.customHeight ? ' dropzone--custom-height' : ''}`}>
        <Dropzone
          className="dropzone__input"
          accept="image/jpeg, image/png"
          name={this.props.name}
          multiple={false}
          onDrop={(filesToUpload) => {
            this.props.onChange(filesToUpload);
          }}
        >
          {(!files || files.length === 0) &&
          <div className="dropzone__drop-here"><span className="lnr lnr-upload" /> Drop file here to upload</div>}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0 &&
        <div className="dropzone__img">
          <img src={this.state.file} className="dropzone-imgsrc" alt="drop-img" />
          <p className="dropzone__img-name">{files[0].name}</p>
          <button className="dropzone__img-delete" onClick={e => this.removeFile(0, e)}>
            Remove
          </button>
        </div>}
      </div>
    );
  }
}

const renderDropZoneField = props => (
  <DropZoneField
    {...props.input}
    customHeight={props.customHeight}
  />
);

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  customHeight: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
  customHeight: false,
};

export default renderDropZoneField;
