import React from 'react';
import { Card, CardBody, Col, ButtonToolbar } from 'reactstrap';
import SaleIcon from 'mdi-react/SaleIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Link } from 'react-router-dom';

const ConfirmationCard = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="email-confirmation">
          <div className="email-confirmation__icon">
            <SaleIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">Ordered successfully</h3>
          <p className="email-confirmation__sub">You can view this order from Pretzel anytime</p>
          <ButtonToolbar className="email-confirmation__buttons">
            <Link className="btn btn-primary" to="/pages/dashboard">Go to Dashboard</Link>
            <Link className="btn btn-primary" to="/pages/order">New Order</Link>
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  </Col>
);


export default ConfirmationCard;
