import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import ContactIcon from 'mdi-react/ScaleBalanceIcon';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/CashIcon';
import TrashIcon from 'mdi-react/EditIcon';
import renderCheckBoxField from '../../Checkbox';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));

const IngredientForm = ({ handleChange, handleSubmit, value, isDisabled, editQty, isQtyDisabled }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="disabled"
          component={renderCheckBoxField}
          label="Disable this item"
          className="colored-click"
          defaultChecked={false}
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Total Quantity</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <Field
          name="quantity"
          component="input"
          type="text"
          placeholder="Quantity"
          parse={parse}
          disabled={isQtyDisabled}
          required
        />
        <button
          className="form__form-group-danger active"
          onClick={(e) => editQty(e)}
          title="Edit quantity without consuming ingredients"
          disabled={isDisabled}
        ><TrashIcon />
        </button>
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Unit</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="unit"
          component="input"
          type="tel"
          disabled
          placeholder="Unit"
        />
      </div>
    </div>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="alert_en"
          component={renderCheckBoxField}
          label="Enable Alert"
          className="colored-click"
          defaultChecked={true}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Alert Level</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <Field
          name="alert"
          component="input"
          type="number"
          placeholder="Alert me when quantity falls below this"
          disabled={isDisabled}
          parse={parse}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Notes</span>
      <div className="form__form-group-field">
        <Field
          name="note"
          component="textarea"
          type="text"
          rows="10"
          placeholder="Notes (optional)"
          disabled={isDisabled}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
    </ButtonToolbar>
  </form>
);

IngredientForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  isQtyDisabled: PropTypes.bool,
  editQty: PropTypes.func
};
  
export default reduxForm({ form: 'ingredientform' })(IngredientForm);
