import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import CatalogItem from './components/CatalogItem';

class CustomPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };

  state = {
    loading: true,
    id: '',
    title: '',
    subtitle: '',
    menu: [],
    custom: {},
    suggestions: [],
    delimiters: [188, 13]
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    if (queries.id) {
      this.load(queries.id);
    }
    this.submit = this.submit.bind(this);
    this.addOption = this.addOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  load = (id) => {
    this.setState({ id: id });

    const params = {
      id: id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/custom');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        const suggestions = body.menu.map((item) => {
          return {
            id: item.sku_id,
            text: item.name
          };
        });
        suggestions.push({
          id: 'o99',
          text: 'None'
        });

        this.setState({
          title: body.custom.name,
          subtitle: body.custom.desc,
          custom: body.custom,
          menu: body.menu.map((item) => {
            item.tags = [];
            item.exists = false;
            if (body.custom) {
              for (let i = 0; i < body.custom.components.length; i++) {
                if (body.custom.components[i].id === item.sku_id && body.custom.components[i].gallery) {
                  item.exists = true;
                  body.custom.components[i].next.map((next) => {
                    if (next === 'o99') {
                      item.tags.push({
                        id: next,
                        text: 'None'
                      });
                    } else {
                      const found = body.menu.filter((m) => { return m.sku_id === next; });
                      if (found.length > 0) {
                        item.tags.push({
                          id: next,
                          text: found[0].name
                        });
                      }
                    }
                    return next;
                  });
                  break;
                }
              }
            }
            return item;
          }).sort((a, b) => {
            return a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0;
          }),
          suggestions: suggestions
        }, () => {
          console.log('state', this.state);
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  submit = (e) => {
    const items = this.state.menu.filter((item) => {
      return item.exists;
    });

    const components = [];

    for (let i = 0; i < items.length; i++) {
      components.push({
        gallery: true,
        id: items[i].sku_id,
        img: {
          description: items[i].name,
          original: 'https://sesamebagels.sg/android-icon-36x36.png',
          thumbnail: 'https://sesamebagels.sg/android-icon-36x36.png'
        },
        max: 0,
        min: 0,
        name: items[i].name,
        next: items[i].tags.length > 0 ? items[i].tags.map((m) => { return m.id; }) : ['o99'],
        points: items[i].shop_points,
        price: items[i].shop_price,
        cat: items[i].cat,
        qty: 1
      });
    }

    let additional = [];
    for (let i = 0; i < components.length; i++) {
      additional.push(...components[i].next);
    }
    additional = [...new Set(additional)];

    for (let i = 0; i < additional.length; i++) {
      if (additional[i] !== 'o99') {
        const found = this.state.menu.filter((m) => { return m.sku_id === additional[i]; });
        if (found.length > 0) {
          components.push({
            id: found[0].sku_id,
            max: 0,
            min: 0,
            name: found[0].name,
            next: found[0].tags.map((m) => { return m.id; }),
            points: found[0].shop_points,
            price: found[0].shop_price,
            cat: found[0].cat,
            qty: 1
          });
        }
      }
    }

    for (let i = 0; i < components.length; i++) {
      if (components[i].next.length === 0) {
        components[i].next.push('o99');
      }
    }

    const custom = this.state.custom;
    custom.components = components;

    this.setState({ loading: true });

    const params = {
      id: this.state.id,
      custom: custom
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/updatecustom');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.props.history.push('/pages/custommenu');
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  addOption(id, option) {
    for (let i = 0; i < this.state.menu.length; i++) {
      if (this.state.menu[i].sku_id === id) {
        const menu = this.state.menu;
        if (menu[i].tags.length === 0) {
          menu[i].tags.push({
            id: 'o99',
            text: 'None'
          });
        }
        menu[i].tags.push(option);
        this.setState({ menu: menu });
        break;
      }
    }
  }

  deleteOption(id, index) {
    for (let i = 0; i < this.state.menu.length; i++) {
      if (this.state.menu[i].sku_id === id) {
        const menu = this.state.menu;
        menu[i].tags.splice(index, 1);
        this.setState({ menu: menu });
        break;
      }
    }
  }

  handleCheck(id, exists) {
    for (let i = 0; i < this.state.menu.length; i++) {
      if (this.state.menu[i].sku_id === id) {
        const menu = this.state.menu;
        menu[i].exists = exists;
        this.setState({ menu: menu });
        break;
      }
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">{this.state.title}</h3>
              <h3 className="page-subhead subhead">{this.state.subtitle}
              </h3>
            </Col>
            <Col md={2}>
              <button className="btn btn-success" onClick={(e) => this.submit(e)}>Update</button>
            </Col>
          </Row>
          {
            this.state.menu.map((item) => 
              (<CatalogItem item={item} suggestions={this.state.suggestions} delimiters={this.state.delimiters} itemChecked={this.handleCheck} itemAddOption={this.addOption} itemDeleteOption={this.deleteOption} />))
          }
        </Container>
      </LoadingScreen>
    );
  }
}

export default withRouter(CustomPage);
