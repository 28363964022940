import React from 'react';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import UserIcon from 'mdi-react/FoodIcon';
import PriceIcon from 'mdi-react/CounterIcon';
import DescIcon from 'mdi-react/NotesIcon';
import CashIcon from 'mdi-react/CashIcon';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../Checkbox';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));

const upper = value => value && value.toUpperCase();

const SkuForm = ({ handleChange, handleSubmit, value, isDisabled, onSelectCat, typeOption }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="disabled"
          component={renderCheckBoxField}
          label="Shown to Public"
          className="colored-click"
          defaultChecked={false}
          disabled={isDisabled}
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name of Promotion or Discount"
          disabled={isDisabled}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Promo Code</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <DescIcon />
        </div>
        <Field
          name="id"
          component="input"
          type="text"
          placeholder="Unique Promotion Code"
          disabled={isDisabled}
          normalize={upper}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <DescIcon />
        </div>
        <Field
          name="description"
          component="input"
          type="text"
          placeholder="Description"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <Row>
        <Col md={6}>
          <span className="form__form-group-label">Discount Type</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <PriceIcon />
            </div>
            <select className="form__form-group-select" value={typeOption} onChange={onSelectCat} disabled={isDisabled}>
              <option key="PERCENT" value="PERCENT">Percent Discount</option>
              <option key="AMOUNT" value="AMOUNT">Amount Discount</option>
            </select>
          </div>
        </Col>
        <Col md={6}>
          <span className="form__form-group-label">Amount</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CashIcon />
            </div>
            <Field
              name="amount"
              component="input"
              type="number"
              placeholder="Amount Discount (either percent % or amount $)"
              disabled={isDisabled}
              parse={parse}
              required
            />
          </div>
        </Col>
      </Row>
    </div>

    <ButtonToolbar className="form__button-toolbar" hidden={isDisabled}>
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
    </ButtonToolbar>
    
  </form>
);

SkuForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  onSelectCat: PropTypes.func,
  typeOption: PropTypes.string
};

export default reduxForm({ form: 'promoform' })(SkuForm);
