import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import Config from '../Config';
import StockForm from './components/StockForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class AddStockPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };

  constructor() {
    super();
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  state = {
    loading: false,
    disabled: false,
    error: '',
    data: {}
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    setTimeout(() => {
      this.setState({
        branch_id: queries.b,
        inventory_id: queries.i,
        data: {
          unit: queries.u
        }
      });
    }, 600);
  }

  update = () => {
    const params = {
      branch_id: this.state.branch_id,
      inventory_id: this.state.inventory_id,
      data: {
        expiry: this.state.data.expiry._d.getTime(),
        purchase: this.state.data.purchase._d.getTime(),
        price: this.state.data.price,
        quantity: this.state.data.quantity
      }
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/stock');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({ loading: false }, () => {
          notify.show('Stock added successfully', 'custom', 5000, {
            background: '#343a40',
            text: '#ddae71'
          });
          this.props.history.push(`/pages/ingredient?d=${this.state.inventory_id}&b=${this.state.branch_id}`);
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };
    
    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  reset = (e) => {
    this.setState({
      data: {}
    });
  }

  submit = (e) => {
    if (!e.expiry || !e.purchase) return;

    this.setState({
      loading: true,
      data: {
        expiry: e.expiry,
        purchase: e.purchase,
        price: e.price * 100,
        quantity: e.quantity,
        alert: e.alert ? e.alert : -1,
        note: e.note ? e.note : 'None'
      }
    }, () => {
      this.update();
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Stock Details</h5>
                    <h5 className="subhead">Additional information for new stock</h5>
                  </div>
                  <StockForm initialValues={this.state.data} onReset={this.reset} onSubmit={this.submit} isDisabled={this.state.disabled} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(AddStockPage);
