import React, { Component } from 'react';
import { Card, CardBody, Alert, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CalendarIcon from 'mdi-react/CalendarIcon';
import RevenueChart from './components/RevenueChart';
import BudgetStatistic from './components/BudgetStatistic';
import VisitorsSessions from './components/VisitorsSessions';
import Panel from '../Panel';
import Config from '../Config';

class DashboardPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    token: PropTypes.string,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getOneMonthAgo().getTime() > new Date('17 OCT 2018').getTime() ? new Date() : new Date('17 OCT 2018').getTime(),
    endDate: this.getTomorrow().getTime(),
    enableSlider: true,
    error: '',
    data: [],
    total: 0,
    cost: 0,
    breakdown: [],
    budget: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.register();
    }, 600);
  }

  formatToDate = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0, 0);
    return date;
  }

  formatToDateEnd = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59, 999);
    return date;
  }

  formatToString = (timestamp) => {
    let date = new Date(timestamp);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1).toString()).slice(-2)}-${('0' + date.getDate().toString()).slice(-2)}`
  }

  getTomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
  }

  getOneMonthAgo() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    console.log(d.getTime());
    return d;
  }

  getOneMonthAgoFrom(date) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() - 7);
    return d;
  }

  getOneMonthLater(date) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() + 7);
    return d;
  }

  loadComplete = (data) => {
    const cal = this.generateData(data);
    const cost = this.generateCost(data);
    this.setState({
      enableSlider: true,
      data: data,
      total: cal.total,
      breakdown: cal.data,
      cost: cost,
      budget: [
        { value: cal.total, fill: '#4ce1b6' },
        { value: cost, fill: '#eeeeee' }
      ]
    });
  }

  updateDateRange = (props) => {
    if (props[0] !== this.state.startDate || props[1] !== this.state.endDate) {
      this.setState({
        enableSlider: false,
        startDate: props[0],
        endDate: props[1]
      });
    }
  }

  register = () => {
    this.setState({ error: '' });

    if (this.props.token) {
      const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/push');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.user.idToken);
  
      const params = {
        token: this.props.token
      };
  
      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = data.body;
        if (data.statusCode === 200) {
          console.log('b', body);
        } else {
          this.setState({ error: body.message });
          console.log('perr', body.message);
        }
        this.setState({ loading: false });
      };
  
      xhr.send(JSON.stringify(params));
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  generateData(data) {
    const cal = {
      total: 0,
      data: [
        { name: 'Shop - Cash', value: 0, fill: '#4ce1b6' },
        { name: 'Shop - Cashless', value: 0, fill: '#70bbfd' },
        { name: 'Wholesale', value: 0, fill: '#f6da6e' },
        { name: 'Online', value: 0, fill: '#ff4861' }
      ]
    };

    for (let i = 0; i < data.length; i++) {
      cal.data[0].value += parseFloat(data[i].a);
      cal.data[1].value += parseFloat(data[i].b);
      cal.data[2].value += parseFloat(data[i].d);
      cal.data[3].value += parseFloat(data[i].c);
      cal.total += parseFloat(data[i].g);
    }

    return cal;
  }

  generateCost(data) {
    let total = 0;

    for (let i = 0; i < data.length; i++) {
      console.log('ccc', data);
      total += parseFloat(data[i].h);
    }

    return total;
  }

  render() {
    return (
      <Container className="dashboard">
        <Alert color="danger" className="bold-text" isOpen={this.state.error}>
          <div className="alert__content">
            {this.state.error}
          </div>
        </Alert>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
          <Col md={2}>
            <Link className="btn btn-success" to="/pages/time">View By Hour</Link>
          </Col>
        </Row>
        
        <Row>
          <Card>
            <CardBody>
              <Row className="form">
                <Col md={3} className="form__form-group">
                  <span className="form__form-group-label">Start Date</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <CalendarIcon />
                    </div>
                    <input
                      name="start_date"
                      type="date"
                      value={this.formatToString(this.state.startDate)}
                      onChange={e => {
                        let newStart = this.formatToDate(e.target.value);
                        let oneMthLimit = this.getOneMonthLater(newStart);
                        if (this.state.endDate <= newStart.getTime() || this.state.endDate > oneMthLimit.getTime()) {
                          this.setState({ 
                            startDate: newStart.getTime(),
                            endDate: oneMthLimit.getTime()
                          });
                        } else {
                          this.setState({ startDate: newStart.getTime() });
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col md={3} className="form__form-group">
                  <span className="form__form-group-label">End Date</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <CalendarIcon />
                    </div>
                    <input
                      name="end_date"
                      type="date"
                      value={this.formatToString(this.state.endDate)}
                      onChange={e => {
                        let newEnd = this.formatToDateEnd(e.target.value);
                        let oneMthLimit = this.getOneMonthAgoFrom(newEnd);
                        if (this.state.startDate >= newEnd.getTime() || this.state.startDate < oneMthLimit.getTime()) {
                          this.setState({ 
                            startDate: oneMthLimit.getTime(),
                            endDate: newEnd.getTime()
                          });
                        } else {
                          this.setState({ endDate: newEnd.getTime() });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          
          <Panel md={12} lg={12} xl={12} title="Revenue Timeline" completed={this.state.updated} loading={this.startLoad} >
            <RevenueChart startDate={this.state.startDate} endDate={this.state.endDate} completion={this.loadComplete} config={this.props.config} />
          </Panel>
        </Row>

        <Row hidden={false}>
          <BudgetStatistic data={this.state.budget} total={this.state.total} cost={this.state.cost} />
          <VisitorsSessions data={this.state.breakdown} total={this.state.total} />
        </Row>
      </Container>
    );
  }
}

export default DashboardPage;
