import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CalendarIcon from 'mdi-react/CalendarIcon';
import OrdersListTable from './components/OrdersListTable';
import Config from '../Config';

class HitchesPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getYesterday().getTime(),
    endDate: this.getHalfMonth().getTime(),
    enableSlider: true,
    data: [],
    enableLoad: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        enableSlider: false
      });
      this.load();
    }, 600);
  }

  formatToDate = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0, 0);
    return date;
  }

  formatToDateEnd = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59, 999);
    return date;
  }

  formatToString = (timestamp) => {
    let date = new Date(timestamp);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1).toString()).slice(-2)}-${('0' + date.getDate().toString()).slice(-2)}`
  }

  getHalfMonth() {
    const d = new Date();
    d.setDate(d.getDate() + 14);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  getYesterday() {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      start: this.state.startDate,
      end: this.state.endDate,
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/hitches');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = data.body;
      if (data.statusCode === 200) {
        this.setState({
          enableSlider: true,
          data: body.records.map((item) => {
            const d = new Date(Math.floor(item.loc_time));
            return {
              date: `${d.getDate()} ${this.monthNames[d.getMonth()]} ${d.getFullYear()} [${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}]`,
              name: item.customer.name,
              phone: item.customer.contact,
              email: item.customer.email,
              price: (item.price / 100).toFixed(2).toString(),
              quantity: item.bagels,
              status: item.status,
              place: item.collection.place
            };
          })
        });
        console.log('d', body);
      } else {
        console.log('e', body.message);
      }
      this.loadComplete();
    };

    this.setState({
      data: []
    }, () => {
      xhr.send(JSON.stringify(params));
    });
  }

  loadComplete = () => {
    this.setState({
      enableSlider: true
    });
  }

  updateDateRange = (props) => {
    const start = new Date(props[0]);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date(props[1]);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);

    if (this.state.enableLoad) {
      this.setState({
        enableLoad: false,
        startDate: start.getTime(),
        endDate: end.getTime(),
        enableSlider: false
      }, () => {
        this.load();
        setTimeout(() => {
          this.setState({
            enableLoad: true
          });
        }, 1000);
      });
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Hitches</h3>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Row className="form">
              <Col md={3} className="form__form-group">
                <span className="form__form-group-label">Start Date</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CalendarIcon />
                  </div>
                  <input
                    name="start_date"
                    type="date"
                    value={this.formatToString(this.state.startDate)}
                    onChange={e => {
                      this.setState({ startDate: this.formatToDate(e.target.value).getTime() }, () => {
                        this.load();
                      });
                    }}
                  />
                </div>
              </Col>
              <Col md={3} className="form__form-group">
                <span className="form__form-group-label">End Date</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CalendarIcon />
                  </div>
                  <input
                    name="end_date"
                    type="date"
                    value={this.formatToString(this.state.endDate)}
                    onChange={e => {
                      this.setState({ endDate: this.formatToDateEnd(e.target.value).getTime() }, () => {
                        this.load();
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <OrdersListTable data={this.state.data} />
        </Row>
      </Container>
    );
  }
}

export default HitchesPage;
