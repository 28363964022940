/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';

const MoneyFormatter = ({ value }) => (
  <div>
    ${value}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/viewadvorder?track=${JSON.parse(value).branch_id}&t=${JSON.parse(value).adv_id}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value === 'delivered' ? <span className="badge badge-danger">Delivered</span> :
    value === 'ofd' ? <span className="badge badge-primary">On the Way</span> :
      value === 'prepared' ? <span className="badge badge-warning">Prepared</span> :
        <span></span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const TypeFormatter = ({ value }) => (
  value === 'delivery' ? <span className="badge badge-danger">Delivery</span> :
    value === 'collection' ? <span className="badge badge-primary">Collection</span> :
      <span className="badge badge-warning">Unknown</span>
);

TypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class OrdersListTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    skus: PropTypes.array
  };

  constructor() {
    super();

    this.heads = [
      {
        key: 'staff_id',
        name: 'Name',
        sortable: true,
      },
      {
        key: 'date',
        name: 'Date',
        sortable: true,
      },
      {
        key: 'price',
        name: 'Total',
        sortable: true,
        formatter: MoneyFormatter,
      },
      {
        key: 'order_type',
        name: 'Type',
        sortable: true,
        formatter: TypeFormatter,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        formatter: StatusFormatter,
      }
    ];

    this.state = {
      pageOfItems: [],
    };
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Row>
        <Col md={12} lg={12}>
          {this.props.data.length > 0
            && <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Orders List</h5>
                </div>
                <EditTable heads={this.heads} rows={this.props.data} />
              </CardBody>
            </Card>
          }
        </Col>
      </Row>
    );
  }
}
