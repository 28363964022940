import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CatalogItem from './components/CatalogItem';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class PromosPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object
  };

  constructor() {
    super();
    this.complete = this.complete.bind(this);
  }

  state = {
    loading: true
  };

  complete() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">Promotions and Discount Management</h3>
              <h3 className="page-subhead subhead">Add or remove promotions
              </h3>
            </Col>
            <Col md={2}>
              <Link className="btn btn-success" to="/pages/promo">Add Discount</Link>
            </Col>
          </Row>
          <Row>
            <CatalogItem onComplete={this.complete} config={this.props.config} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default PromosPage;
