import React, { Component } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CashIcon from 'mdi-react/CashIcon';
import OrdersListTable from './components/OrdersListTable';
import DoubleSliderType from '../DoubleSliderType';
import Config from '../Config';

const getTodayDate = () => {
  const now = new Date();
  return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
}

const getTomorrowDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
}

class AdvOrdersPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getToday().getTime(),
    endDate: this.getLater().getTime(),
    enableSlider: true,
    data: [],
    enableLoad: true,
    locations: [],
    schedules: {},
    selected_start_date: getTodayDate(),
    selected_start_time: '00:00',
    selected_end_date: getTomorrowDate(),
    selected_end_time: '00:00',
    selected_location: '',
    selected_schedule: 0,
    refresh: true
  }

  orders = [];

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        enableSlider: false
      });
      this.load();
    }, 600);
  }

  getLater() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  getToday() {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = () => {
    this.setState({
      data: []
    });

    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    };

    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': Config.API_KEY,
      'Authorization': this.props.config.user.idToken
    };
  
    axios.post('https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/advorders', JSON.stringify(params), {
      headers: headers
    }).then(res => {
      const body = JSON.parse(res.data.body);
      if (res.data.statusCode === 200) {
        this.orders = body.orders;
        this.setSchedule(body.orders);
        this.setList(body.orders);
      } else {
        console.log('e', body.message);
      }
      this.loadComplete();
    });
  }

  setSchedule(orders) {
    const locations = [];
    const schedules = {};

    for (let i = 0; i < orders.length; i++) {
      if (orders[i].loc_id === '0') {
        locations.push({
          id: orders[i].collection.place + orders[i].loc_time.toString(),
          name: orders[i].collection.place
        });

        schedules[orders[i].collection.place + orders[i].loc_time] = [{
          time: orders[i].loc_time,
          details: orders[i].collection.details
        }];
      } else if (orders[i].loc_id in schedules) {
        if (schedules[orders[i].loc_id].filter(s => s.time === orders[i].loc_time).length === 0) {
          schedules[orders[i].loc_id].push({
            time: orders[i].loc_time,
            details: orders[i].collection.details
          });
        }
      } else {
        locations.push({
          id: orders[i].loc_id,
          name: orders[i].collection.place
        });

        schedules[orders[i].loc_id] = [{
          time: orders[i].loc_time,
          details: orders[i].collection.details
        }];
      }
    }

    this.setState({
      locations: locations,
      schedules: schedules
    });
  }

  setList(orders) {
    const carts = {};
    
    for (let i = 0; i < orders.length; i++) {
      for (let j = 0; j < orders[i].cart.length; j++) {
        let name = orders[i].cart[j].name;
        if (orders[i].cart[j].selected) {
          name += ' + ' + orders[i].cart[j].selected.name;
        }
        if (orders[i].cart[j].notes) {
          name += ' - ' + orders[i].cart[j].notes;
        }
        if (name in carts) {
          carts[name].qty += orders[i].cart[j].qty;
        } else {
          carts[name] = {
            name: name,
            qty: orders[i].cart[j].qty
          };
        }
      }
    }

    this.setState({
      refresh: !this.state.refresh,
      items: Object.keys(carts).map(key => {
        return {
          id: key,
          name: carts[key].name,
          qty: carts[key].qty
        };
      }).sort(item => item.id),
      data: orders.map((item) => {
        const d = new Date(item.collection.time);
        return {
          id: `#${item.adv_id.toString().slice(-6)}`,
          date: `${d.getDate()} ${this.monthNames[d.getMonth()]} ${d.getFullYear()} [${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}]`,
          name: item.customer.name,
          price: (item.price / 100).toFixed(2).toString(),
          quantity: item.cart.length,
          order_type: item.order_type,
          //status: item.delivered ? 'delivered' : item.ofd ? 'ofd' : item.prepared ? 'prepared' : '',
          status: item.cart.filter(item => item.notes).length > 0 ? '📝 Notes' : '-',
          tracker: JSON.stringify({
            branch_id: item.branch_id,
            adv_id: item.adv_id
          })
        };
      })
    }, () => this.loadComplete());
  }

  loadComplete = () => {
    this.setState({
      enableSlider: true
    });
  }

  updateDateRange = (props) => {
    const start = new Date(props[0]);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date(props[1]);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);

    if (this.state.enableLoad) {
      this.setState({
        enableLoad: false,
        startDate: start.getTime(),
        endDate: end.getTime(),
        enableSlider: false
      }, () => {
        this.load();
        setTimeout(() => {
          this.setState({
            enableLoad: true
          });
        }, 1000);
      });
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  formatToDatestr = (now) => {
    return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
  }

  formatToDate = (datestr, timestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    const hm = timestr.split(':');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(parseInt(hm[0], 10));
    date.setMinutes(parseInt(hm[1], 10));
    date.setSeconds(0, 0);
    return date;
  }

  formatToNextDate = (datestr, timestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    const hm = timestr.split(':');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(parseInt(hm[0], 10)+23);
    date.setMinutes(parseInt(hm[1], 10)+59);
    date.setSeconds(59, 999);
    return date;
  }

  formatDateToString = (date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1).toString()).slice(-2)}-${('0' + date.getDate().toString()).slice(-2)}`;
  }

  changedRange = () => {
    const start = this.formatToDate(this.state.selected_start_date, this.state.selected_start_time);
    let end = this.formatToDate(this.state.selected_end_date, this.state.selected_end_time);

    let update = {
      enableSlider: false,
      startDate: start.getTime(),
      endDate: end.getTime(),
      selected_location: '',
      selected_schedule: 0
    };

    if (end <= start) {
      end = this.formatToNextDate(this.state.selected_start_date, this.state.selected_start_time);
      update.endDate = end.getTime();
      update.selected_end_date = this.formatDateToString(end);
    }

    if (start >= end) {
      end.setDate(start.getDate() + 1);
      update['endDate'] = end.getTime();
      update['selected_end_date'] = this.formatToDatestr(end);
    }

    this.setState(update, () => {
      this.load();
    });
  }

  changedSchedule = () => {
    this.setList(this.orders.filter(order => {
      if (this.state.selected_location) {
        if (order.loc_id === '0') {
          return (order.collection.place + order.loc_time.toString()) === this.state.selected_location;
        } else {
          return order.loc_id === this.state.selected_location;
        }
      } else {
        return true;
      }
    }).filter(order => {
      if (this.state.selected_schedule) {
        return order.loc_time.toString() === this.state.selected_schedule;
      } else {
        return true;
      }
    }))
  }

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Upcoming Orders</h3>
          </Col>
        </Row>
        
        {/*<DoubleSliderType 
          start={this.state.startDate} 
          end={this.state.endDate} 
          enabled={this.state.enableSlider} 
          update={this.updateDateRange}
          label="Based on the date the order is created"
        />*/}

        <Card>
          <CardBody>
            <Row className="form">
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">Start Date</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="start_date"
                  type="date"
                  value={this.state.selected_start_date}
                  onChange={e => {
                    this.setState({ selected_start_date: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} hidden={true} className="form__form-group">
              <span className="form__form-group-label">Start Time</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="start_time"
                  type="time"
                  value={this.state.selected_start_time}
                  onChange={e => {
                    this.setState({ selected_start_time: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">End Date</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="end_date"
                  type="date"
                  value={this.state.selected_end_date}
                  onChange={e => {
                    this.setState({ selected_end_date: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} hidden={true} className="form__form-group">
              <span className="form__form-group-label">End Time</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="end_time"
                  type="time"
                  value={this.state.selected_end_time}
                  onChange={e => {
                    this.setState({ selected_end_time: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">Location</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <select className="form__form-group-select"
                  onChange={e => this.setState({
                    selected_location: e.target.value,
                    selected_schedule: 0
                  }, () => this.changedSchedule())}
                  value={this.state.selected_location}>
                  <option defaultValue value="">Any</option>
                  {
                    this.state.locations.map((location) => (
                      <option value={location.id}>{location.name}</option>
                    ))
                  }
                </select>
              </div>
            </Col>
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">Schedule</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <select className="form__form-group-select" 
                  onChange={e => this.setState({ selected_schedule: e.target.value }, () => this.changedSchedule())}
                  value={this.state.selected_schedule}>
                  <option defaultValue value={0}>Any</option>
                  {
                    this.state.selected_location &&
                    this.state.schedules[this.state.selected_location].map((schedule) => (
                      <option value={schedule.time}>{schedule.details}</option>
                    ))
                  }
                </select>
              </div>
            </Col>
          </Row>
          </CardBody>
        </Card>

        <OrdersListTable refresh={this.state.refresh} data={{
          data: this.state.data,
          items: this.state.items
        }} />
      </Container>
    );
  }
}

export default AdvOrdersPage;
