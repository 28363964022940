/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';

const MoneyFormatter = ({ value }) => (
  <div>
      ${value}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const TypeFormatter = ({ value }) => (
  value === 'OK' ? <span className="badge badge-success">Available</span> : <span className="badge badge-danger">Low Stock</span>
);

TypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class IngredientsTable extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'name',
        name: 'Name',
        width: 240,
        sortable: true,
      },
      {
        key: 'quantity',
        name: 'Quantity',
        sortable: true,
      },
      {
        key: 'unit',
        name: 'Units',
        sortable: true,
      },
      {
        key: 'status',
        name: 'Status',
        formatter: TypeFormatter,
        width: 160,
        sortable: true,
      },
      {
        key: 'alert_qty',
        name: 'Alert Level',
        sortable: true,
      }
    ];

    this.state = {
      pageOfItems: [],
      pageSize: 10
    };
  }

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Ingredients list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
