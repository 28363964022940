/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Catalog extends Component {
  static propTypes = {
    config: PropTypes.object,
    data: PropTypes.array
  };

  render() {
    return (
      <main>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.props.data.map((item, i) =>
              (
                <Link className="catalog-item-colored" key={i} to={`/pages/ingredient?d=${item.id}&b=${this.props.config.user.branches[0].branch_id}`} >
                  <div className="catalog-item__link">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={9}>
                          <h4 className="catalog-item__title">{item.name}</h4>
                        </Col>
                        <Col md={3}>
                          <h3 className="catalog-item__price">{item.req} {item.unit}</h3>
                        </Col>
                      </Row>
                      <p className="catalog-item__description">You have {item.quantity} {item.unit} available</p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </main>
    );
  }
}
