import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactIcon from 'mdi-react/PhoneIcon';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/MoneyIcon';
import TimeIcon from 'mdi-react/ClockIcon';
import queryString from 'query-string';
import Config from '../Config';
import LoadingScreen from '../../shared/components/loading';
import Logo from '../../shared/img/logo.png';

class ViewOrderPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    loading: true,
    timestamp: 0,
    ordertime: 0,
    branch_id: '',
    cart: [],
    collected: true,
    name: '',
    price: 0,
    type: '',
    address: '',
    notes: '',
    error: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.load(queries.track, parseInt(queries.t, 10));
  }

  getDateStringFromId(id) {
    const d = new Date(Math.floor(id / 100));
    return `${d.getDate()} ${this.monthNames[d.getMonth()]} ${d.getFullYear()}`;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = (track, t) => {
    this.setState({
      branch_id: track,
      timestamp: t
    });

    const params = {
      branch_id: track,
      timestamp: t
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/order');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        const cart = body.order.cart.concat(body.order.customs);
        console.log('ttt', cart);
        this.setState({
          cart: cart,
          collected: body.order.collected,
          timestamp: body.order.timestamp,
          ordertime: body.order.order_id,
          name: body.order.customer.name,
          price: body.order.paid_amount,
          type: body.order.type,
          address: body.order.details ? body.order.details.address : '',
          notes: body.order.details ? body.order.details.note : '',
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  collected = () => {
    this.setState({
      collected: true
    });

    const params = {
      branch_id: this.state.branch_id,
      timestamp: this.state.timestamp
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/collect');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('done');
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Order #{this.state.timestamp}</h3>
            </Col>
          </Row>
          <Row hidden={this.state.loading}>
            <Col md={4}>
              <Card>
                <CardBody>
                  {this.state.cart.map((item) => (
                    <div className="checkoutlist__collapse-item" key={item.id}>
                      <p className="checkoutlist__collapse-name">{item.name} {item.pairName ? `(${item.pairName})` : ''}</p>
                      <p className="checkoutlist__collapse-date">x{item.qty}</p>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Order Note</h5>
                    <h5 className="subhead">Enter additional information for record</h5>
                  </div>
                  <form className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Total Price</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <PriceIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={(this.state.price / 100).toFixed(2)}
                          disabled={true}
                          placeholder="Total Price"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <UserIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.state.name}
                          disabled={true}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Order Type</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <ContactIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="tel"
                          value={this.state.type}
                          disabled={true}
                          placeholder="Contact Number (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Order Time</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimeIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.getDateStringFromId(this.state.ordertime)}
                          disabled={true}
                          placeholder="Address (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Collection Time</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimeIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.getDateStringFromId(this.state.timestamp)}
                          disabled={true}
                          placeholder="Address (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Address</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          value={this.state.address}
                          disabled={true}
                          placeholder="None"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Notes</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          value={this.state.notes}
                          disabled={true}
                          placeholder="None"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={() => this.collected()} disabled={this.state.collected}>Set as Collected</Button>
                    </ButtonToolbar>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default ViewOrderPage;
