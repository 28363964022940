/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/AlertIcon';

export default class TopbarNotification extends Component {
  static propTypes = {
    notification: PropTypes.array,
    removeNotification: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      collapse: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  empty = () => {
    this.props.removeNotification();
  }

  render() {
    return (
      <div className="topbar__collapse">
        <button className={`topbar__btn topbar__btn--mail ${this.props.notification.length > 0 ? 'topbar__btn--new' : ''}`} onClick={this.toggle}>
          <Icon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Alerts</p>
            <button className="topbar__collapse-button" onClick={this.empty}>Clear</button>
          </div>
          {this.props.notification.map((notif, index) => (
            <div className="topbar__collapse-item" key={index}>
              <p className="topbar__collapse-name">{notif.body}</p>
            </div>
          ))}
        </Collapse>
      </div>
    );
  }
}
