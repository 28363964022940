import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CalendarIcon from 'mdi-react/CalendarIcon';
import OrdersListTable from './components/OrdersListTable';
import DoubleSliderType from '../DoubleSliderType';
import Config from '../Config';

class OrdersPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getTwoDaysAgo().getTime(),
    endDate: this.getTomorrow().getTime(),
    enableSlider: true,
    data: [],
    enableLoad: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        enableSlider: false
      });
      this.load();
    }, 600);
  }

  formatToDate = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0, 0);
    return date;
  }

  formatToDateEnd = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59, 999);
    return date;
  }

  formatToString = (timestamp) => {
    let date = new Date(timestamp);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1).toString()).slice(-2)}-${('0' + date.getDate().toString()).slice(-2)}`
  }

  getOneMonthAgoFrom(date) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() - 1);
    return d;
  }

  getOneMonthLater(date) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() + 1);
    return d;
  }

  getTomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  getTwoDaysAgo() {
    const d = new Date();
    d.setDate(d.getDate() - 2);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/orders');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          enableSlider: true,
          data: body.orders.map((item) => {
            const d = new Date(Math.floor(item.timestamp / 100));
            return {
              id: `#${item.order_id.toString().slice(-6)}`,
              date: `${d.getDate()} ${this.monthNames[d.getMonth()]} ${d.getFullYear()} [${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}]`,
              name: item.customer.name,
              price: (item.paid_amount / 100).toFixed(2).toString(),
              quantity: item.cart.length,
              type: item.type,
              status: item.collected ? 'Collected' : 'Pending',
              tracker: JSON.stringify({
                branch_id: item.branch_id,
                timestamp: item.timestamp
              })
            };
          }).reverse()
        });
        console.log('d', body);
      } else {
        console.log('e', body.message);
      }
      this.loadComplete();
    };

    this.setState({
      data: []
    }, () => {
      xhr.send(JSON.stringify(params));
    });
  }

  loadComplete = () => {
    this.setState({
      enableSlider: true
    });
  }

  updateDateRange = (props) => {
    const start = new Date(props[0]);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date(props[1]);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);

    if (this.state.enableLoad) {
      this.setState({
        enableLoad: false,
        startDate: start.getTime(),
        endDate: end.getTime(),
        enableSlider: false
      }, () => {
        this.load();
        setTimeout(() => {
          this.setState({
            enableLoad: true
          });
        }, 1000);
      });
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Orders Chart</h3>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Row className="form">
              <Col md={3} className="form__form-group">
                <span className="form__form-group-label">Start Date</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CalendarIcon />
                  </div>
                  <input
                    name="start_date"
                    type="date"
                    value={this.formatToString(this.state.startDate)}
                    onChange={e => {
                      let newStart = this.formatToDate(e.target.value);
                      let oneMthLimit = this.getOneMonthLater(newStart);
                      if (this.state.endDate <= newStart.getTime() || this.state.endDate > oneMthLimit.getTime()) {
                        this.setState({ 
                          startDate: newStart.getTime(),
                          endDate: oneMthLimit.getTime()
                        }, () => this.load());
                      } else {
                        this.setState({ startDate: newStart.getTime() }, () => this.load());
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md={3} className="form__form-group">
                <span className="form__form-group-label">End Date</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CalendarIcon />
                  </div>
                  <input
                    name="end_date"
                    type="date"
                    value={this.formatToString(this.state.endDate)}
                    onChange={e => {
                      let newEnd = this.formatToDateEnd(e.target.value);
                      let oneMthLimit = this.getOneMonthAgoFrom(newEnd);
                      if (this.state.startDate >= newEnd.getTime() || this.state.startDate < oneMthLimit.getTime()) {
                        this.setState({ 
                          startDate: oneMthLimit.getTime(),
                          endDate: newEnd.getTime()
                        }, () => this.load());
                      } else {
                        this.setState({ endDate: newEnd.getTime() }, () => this.load());
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <OrdersListTable data={this.state.data} />
        </Row>
      </Container>
    );
  }
}

export default OrdersPage;
