/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';
import Pagination from '../../Pagination';

const MoneyFormatter = ({ value }) => (
  <div>
      $ {value}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class SalesListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'id',
        name: 'ID',
        width: 160,
        sortable: true,
      },
      {
        key: 'name',
        name: 'Item Name',
        sortable: true,
      },
      {
        key: 'price',
        name: 'Revenue',
        sortable: true,
        formatter: MoneyFormatter,
      },
      {
        key: 'quantity',
        name: 'Quantity',
        sortable: true,
      }
    ];

    this.state = {
      pageOfItems: [],
      pageSize: 5
    };
  }

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Sales list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
