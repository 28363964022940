import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/UserIcon';
import renderCheckBoxField from '../../Checkbox';

const CategoryForm = ({ handleChange, handleSubmit, value, isDisabled }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name="visible"
          component={renderCheckBoxField}
          label="Visible"
          className="colored-click"
          defaultChecked={true}
          disabled
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
    </ButtonToolbar>
  </form>
);

CategoryForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool
};
  
export default reduxForm({ form: 'categoryform' })(CategoryForm);
