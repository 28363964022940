import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import Config from '../Config';
import EditTable from './components/EditableTable';
import IngredientForm from './components/IngredientForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class IngredientPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    loading: true,
    branch_id: '',
    inventory_id: '',
    name: '',
    note: '',
    quantity: 0,
    stock: [],
    unit: '',
    qtyDisabled: true,
    error: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.load(queries.d, queries.b);
    this.submit = this.submit.bind(this);
    this.edit = this.edit.bind(this);
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = (inventoryId, branchId) => {
    this.setState({
      branch_id: branchId,
      inventory_id: inventoryId
    });

    const params = {
      branch_id: branchId,
      inventory_id: inventoryId
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/getinventory');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        const stocks = body.inventory.stock.map((item) => {
          const exp = new Date(item.expiry);
          const pur = new Date(item.purchase);
          return {
            expiry: `${exp.getDate()} ${this.monthNames[exp.getMonth()]}`,
            purchase: `${pur.getDate()} ${this.monthNames[pur.getMonth()]}`,
            quantity: item.quantity,
            price: `$${(item.price / 100).toFixed(2)}`,
            note: item.note,
            exp: item.expiry,
            ret: item
          };
        }).sort((a, b) => {
          return (a.exp > b.exp) ? 1 : ((b.exp > a.exp) ? -1 : 0);
        });

        const newArr = [];
        let remain = body.inventory.quantity;
        let final = 0;

        for (let i = (stocks.length - 1); i >= 0; i--) {
          if (remain > 0) {
            if (remain >= stocks[i].quantity) {
              newArr.push(stocks[i]);
              remain -= stocks[i].quantity;
              final += stocks[i].quantity;
            } else {
              stocks[i].quantity = remain;
              stocks[i].ret.quantity = remain;
              final += remain;
              newArr.push(stocks[i]);
              remain = 0;
            }
          }
        }

        this.setState({
          data: {
            name: body.inventory.name,
            note: body.inventory.note,
            quantity: final,
            alert: body.inventory.alert_qty,
            alert_en: body.inventory.alert,
            unit: body.inventory.unit,
            disabled: body.inventory.disabled
          },
          stock: newArr,
        }, () => {
          this.update(false);
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  update = (notice) => {
    this.setState({ error: '' });

    const params = {
      branch_id: this.state.branch_id,
      inventory_id: this.state.inventory_id,
      data: {
        name: this.state.data.name,
        unit: this.state.data.unit,
        alert: this.state.data.alert ? this.state.data.alert : 0,
        alert_en: this.state.data.alert_en,
        quantity: this.state.data.quantity,
        note: this.state.data.note,
        disabled: this.state.data.disabled,
        stock: this.state.stock.map((item) => {
          return {
            expiry: item.ret.expiry,
            purchase: item.ret.purchase,
            price: item.ret.price,
            quantity: item.ret.quantity
          };
        })
      }
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/updateingredient');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({ loading: false }, () => {
          if (notice) {
            notify.show('Ingredient updated successfully', 'custom', 5000, {
              background: '#343a40',
              text: '#ddae71'
            });
          }
        });
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  edit = (e) => {
    e.preventDefault();
    this.setState({ qtyDisabled: false });
  }

  submit = (e) => {
    this.setState({
      loading: true,
      data: {
        disabled: e.disabled,
        name: e.name,
        unit: e.unit,
        quantity: e.quantity,
        alert: e.alert,
        alert_en: e.alert_en,
        note: e.note ? e.note : 'None'
      }
    }, () => {
      this.update(true);
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={6}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={8} className="card__title">
                      <h5 className="bold-text">Stock List</h5>
                      <h5 className="subhead">Existing stocks for ingredients</h5>
                    </Col>
                    <Col md={4}>
                      <Link className="btn btn-success" to={`/pages/stock?b=${this.state.branch_id}&i=${this.state.inventory_id}&u=${this.state.data ? this.state.data.unit : ''}`}>Add Stock</Link>
                    </Col>
                  </Row>
                  <EditTable stock={this.state.stock} />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Additional information for ingredients</h5>
                  </div>
                  <IngredientForm initialValues={this.state.data} onSubmit={this.submit} isDisabled={this.state.loading} isQtyDisabled={this.state.qtyDisabled} editQty={this.edit} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default IngredientPage;
