import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/UserIcon';
import IdIcon from 'mdi-react/PermIdentityIcon';
import LockIcon from 'mdi-react/LockIcon';

const ElementForm = ({ handleChange, handleSubmit, value, isDisabled, isNew }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={!isNew || isDisabled}
          required
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Username</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <IdIcon />
        </div>
        <Field
          name="id"
          component="input"
          type="text"
          placeholder="Username"
          disabled={!isNew || isDisabled}
          required
        />
      </div>
    </div>

    <div className="form__form-group" hidden={!isNew}>
      <span className="form__form-group-label">Password</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <LockIcon />
        </div>
        <Field
          name="password"
          component="input"
          type="text"
          placeholder="Password"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <ButtonToolbar className="form__button-toolbar" hidden={!isNew}>
      <Button color="primary" type="submit" disabled={isDisabled}>Create Account</Button>
    </ButtonToolbar>
  </form>
);

ElementForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  isNew: PropTypes.bool
};
  
export default reduxForm({ form: 'elementform' })(ElementForm);
