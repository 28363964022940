/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../Panel';

const style = {
  left: 0,
  width: 150,
  lineHeight: '24px',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

class VisitorsSessions extends Component {
  static propTypes = {
    data: PropTypes.array,
    total: PropTypes.number
  };

  render() {
    return (
      <Panel
        lg={6}
        xl={6}
        md={12}
        title="Sales Breakdown"
        subhead="Revenue by type for this month"
      >
        <div className="dashboard__visitors-chart">
          <p className="dashboard__visitors-chart-title">Total Sales</p>
          <p className="dashboard__visitors-chart-number">${this.props.total.toFixed(2)}</p>
          <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={220}>
            <PieChart className="dashboard__chart-pie-container">
              <Tooltip />
              <Pie data={this.props.data} dataKey="value" cy={110} innerRadius={70} outerRadius={100} />
              <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}

export default VisitorsSessions;
