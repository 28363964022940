import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { notify } from 'react-notify-toast';
import { Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Config from '../../Config';

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      showError: false,
      errorMessage: '',
      enableInput: true
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.config.user.username && newProps.config.user.password) {
      this.nameRef.value = newProps.config.user.username;
      this.passwordRef.value = newProps.config.user.password;
    }
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  login = (username, password) => {
    notify.show('Logging on, please wait', 'custom', 5000, {
      background: '#343a40',
      text: '#ddae71'
    });

    this.setState({
      showError: false,
      errorMessage: '',
      enableInput: false
    });

    this.props.start();

    const authenticationDetails = new AuthenticationDetails({
      Username: this.nameRef.value,
      Password: this.passwordRef.value
    });
    const userPool = new CognitoUserPool({
      UserPoolId: 'ap-southeast-1_AG2entuKs',
      ClientId: '6m2mv1h68apk1n6brmsqso6fg7'
    });
    const userData = {
      Username: this.nameRef.value,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/branches');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-api-key', Config.API_KEY);
        xhr.setRequestHeader('Authorization', result.idToken.jwtToken);

        xhr.onload = () => {
          const data = JSON.parse(xhr.responseText);
          const body = JSON.parse(data.body);
          if (data.statusCode === 200) {
            const config = {
              user: {
                idToken: result.idToken.jwtToken,
                accessToken: result.getAccessToken().getJwtToken(),
                refreshToken: result.getRefreshToken().getToken(),
                expiry: result.idToken.payload.exp * 1000,
                id: result.accessToken.payload.username,
                username: this.nameRef.value.toString(),
                password: this.passwordRef.value.toString(),
                name: result.idToken.payload.name,
                email: result.idToken.payload.email,
                phone: result.idToken.payload.phone_number,
                branches: body.branches
              },
            };
    
            this.props.update(config);

            this.props.history.push('/pages/dashboard');
          } else {
            console.log('e', body);
            this.setState({
              showError: true,
              errorMessage: body.message,
              enableInput: true
            }, () => {
              notify.hide();
              this.props.stop();
            });
          }
        };
    
        xhr.send();
      },

      onFailure: (err) => {
        console.log('e', err);
        this.setState({
          showError: true,
          errorMessage: err.message,
          enableInput: true
        }, () => {
          notify.hide();
          this.props.stop();
        });
      },
    });
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <main>
        <Alert color="danger" className="bold-text" isOpen={this.state.showError}>
          <div className="alert__content">
            {this.state.errorMessage}
          </div>
        </Alert>
        <form className="form">
          <div className="form__form-group">
            <span className="form__form-group-label">Username</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <input
                name="name"
                component="input"
                type="text"
                placeholder="Name"
                ref={(ref) => { this.nameRef = ref; }}
                disabled={!this.state.enableInput}
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <input
                name="password"
                component="input"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password"
                ref={(ref) => { this.passwordRef = ref; }}
                disabled={!this.state.enableInput}
                required
              />
              <button
                className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
              ><EyeIcon />
              </button>
            </div>
            <div className="account__forgot-password">
              <a href="/">Forgot a password?</a>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <br />
            </div>
          </div>
        </form>
        <button
          className="btn btn-primary account__btn account__btn--small"
          disabled={!this.state.enableInput}
          onClick={() => this.login()}
        >Sign In
        </button>
      </main>
    );
  }
}

LogInForm.propTypes = {
  history: PropTypes.object,
  update: PropTypes.func,
  menu: PropTypes.func,
  config: PropTypes.object,
  start: PropTypes.func,
  stop: PropTypes.func
};

export default withRouter(LogInForm);
