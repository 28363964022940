import React, { Component } from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import IngredientsTable from './components/IngredientsListTable';
import SkusTable from './components/SkusListTable';
import Config from '../Config';
import LoadingScreen from '../../shared/components/loading';
import Logo from '../../shared/img/logo.png';

class InventoryOverviewPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    loadingIngredients: true,
    loadingSkus: true,
    dataIngredients: [],
    dataSkus: [],
    error: ''
  };

  componentDidMount() {
    setTimeout(() => {
      this.loadIngredients();
      this.loadSkus();
    }, 600);
  }

  loadIngredients = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/ingredients');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          dataIngredients: body.ingredients.map((item) => {
            const it = item;
            it.status = it.alert_qty >= it.quantity ? 'LOW' : 'OK';
            return it;
          }).sort((a, b) => {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          })
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loadingIngredients: false });
    };

    xhr.send(JSON.stringify(params));
  }

  loadSkus = () => {
    const params = {
      branch_id: this.props.config.user.branches[0].branch_id
    };

    const xhr = this.createCORSRequest('POST', 'https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/menuinventory');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          dataSkus: body.menu.map((item) => {
            const it = item;
            it.status = it.quantity <= 0 ? 'LOW' : 'OK';
            return it;
          }).sort((a, b) => {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          })
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loadSkus: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loadingIngredients && this.state.loadingSkus}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Inventory Overview</h3>
            </Col>
            <Col md={6}>
              <IngredientsTable data={this.state.dataIngredients} />
            </Col>
            <Col md={6}>
              <SkusTable data={this.state.dataSkus} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default InventoryOverviewPage;
