import React, { Component } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CashIcon from 'mdi-react/CashIcon';
import OrdersListTable from './components/OrdersListTable';
import DoubleSliderType from '../DoubleSliderType';
import Config from '../Config';

const getTodayDate = () => {
  const now = new Date();
  return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
}

const getTomorrowDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
}

class StaffOrdersPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateConfig: PropTypes.func
  };

  state = {
    startDate: this.getToday().getTime(),
    endDate: this.getLater().getTime(),
    enableSlider: true,
    data: [],
    staffs: [],
    enableLoad: true,
    selected_start_date: getTodayDate(),
    selected_start_time: '00:00',
    selected_end_date: getTomorrowDate(),
    selected_end_time: '00:00',
    selected_staff: '',
    refresh: true
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        enableSlider: false
      });
      this.loadStaffs();
    }, 600);
  }

  getLater() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  getToday() {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  loadStaffs = () => {
    const params = {
      branch: this.props.config.user.branches[0].branch_id
    };

    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': Config.API_KEY,
      'Authorization': this.props.config.user.idToken
    };
  
    axios.post('https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/elements', JSON.stringify(params), {
      headers: headers
    }).then(res => {
      const body = JSON.parse(res.data.body);
      if (res.data.statusCode === 200) {
        console.log('test staffs', body.elements);
        this.setState({
          staffs: body.elements
        }, () => this.load());
      } else {
        console.log('e', body.message);
        this.loadComplete();
      }
    });
  }

  load = () => {
    if (!this.state.selected_staff) {
      this.loadComplete();
      return;
    }

    console.log('test load');

    this.setState({
      data: []
    });

    const params = {
      staff: this.state.selected_staff,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    };

    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': Config.API_KEY,
      'Authorization': this.props.config.user.idToken
    };
  
    axios.post('https://6r0wg06ha6.execute-api.ap-southeast-1.amazonaws.com/prod/stafforders', JSON.stringify(params), {
      headers: headers
    }).then(res => {
      const body = JSON.parse(res.data.body);
      if (res.data.statusCode === 200) {
        console.log('test l', body.orders);
        this.setState({
          data: body.orders.map(order => {
            const d = new Date(order.loc_time);
            order.price = parseFloat(order.price / 100).toFixed(2);
            order.date = `${d.getDate()} ${this.monthNames[d.getMonth()]} ${d.getFullYear()} [${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}]`;
            order.status = order.delivered ? 'delivered' : order.ofd ? 'ofd' : order.prepared ? 'prepared' : ''
            return order;
          })
        });
      } else {
        console.log('e', body.message);
      }
      this.loadComplete();
    });
  }

  loadComplete = () => {
    this.setState({
      enableSlider: true
    });
  }

  updateDateRange = (props) => {
    const start = new Date(props[0]);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date(props[1]);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);

    if (this.state.enableLoad) {
      this.setState({
        enableLoad: false,
        startDate: start.getTime(),
        endDate: end.getTime(),
        enableSlider: false
      }, () => {
        this.load();
        setTimeout(() => {
          this.setState({
            enableLoad: true
          });
        }, 1000);
      });
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  formatToDatestr = (now) => {
    return now.getFullYear() + "-" + ('0'+(now.getMonth()+1).toString()).slice(-2) + "-" + ('0'+now.getDate().toString()).slice(-2);
  }

  formatToDate = (datestr, timestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    const hm = timestr.split(':');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10)-1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(parseInt(hm[0], 10));
    date.setMinutes(parseInt(hm[1], 10));
    date.setSeconds(0, 0);
    return date;
  }

  changedRange = () => {
    const start = this.formatToDate(this.state.selected_start_date, this.state.selected_start_time);
    const end = this.formatToDate(this.state.selected_end_date, this.state.selected_end_time);
    const update = {
      enableSlider: false,
      startDate: start.getTime(),
      endDate: end.getTime(),
      selected_location: '',
      selected_schedule: 0
    };

    if (start >= end) {
      end.setDate(start.getDate() + 1);
      update['endDate'] = end.getTime();
      update['selected_end_date'] = this.formatToDatestr(end);
    }

    this.setState(update, () => {
      this.load();
    });
  }

  changedStaff = () => {
    this.load();
  }

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Staff Deliveries</h3>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Row className="form">
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">Start Date</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="start_date"
                  type="date"
                  value={this.state.selected_start_date}
                  onChange={e => {
                    this.setState({ selected_start_date: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} hidden={true} className="form__form-group">
              <span className="form__form-group-label">Start Time</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="start_time"
                  type="time"
                  value={this.state.selected_start_time}
                  onChange={e => {
                    this.setState({ selected_start_time: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">End Date</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="end_date"
                  type="date"
                  value={this.state.selected_end_date}
                  onChange={e => {
                    this.setState({ selected_end_date: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} hidden={true} className="form__form-group">
              <span className="form__form-group-label">End Time</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <input
                  name="end_time"
                  type="time"
                  value={this.state.selected_end_time}
                  onChange={e => {
                    this.setState({ selected_end_time: e.target.value }, () => this.changedRange());
                  }}
                />
              </div>
            </Col>
            <Col md={3} className="form__form-group">
              <span className="form__form-group-label">Staff</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CashIcon />
                </div>
                <select className="form__form-group-select" 
                  onChange={e => this.setState({ selected_staff: e.target.value }, () => this.changedStaff())}
                  value={this.state.selected_staff}>
                  <option defaultValue value="">None</option>
                  {
                    this.state.staffs.map((staff) => (
                      <option value={staff.name}>{staff.name}</option>
                    ))
                  }
                </select>
              </div>
            </Col>
          </Row>
          </CardBody>
        </Card>

        <OrdersListTable data={this.state.data} />
      </Container>
    );
  }
}

export default StaffOrdersPage;
